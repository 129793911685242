import React, {useState, useContext, useEffect} from "react";
import {login, retornaPerfilPeloId, alterarSenha} from "../../api/DataApi";
import { useNavigate } from "react-router-dom";
import LogoCaixa from '../../imagens/caixa_assistencia_2d_vertical_negativa.png';
import Imagem1 from '../../imagens/imagem_1.png';
import { AppContext } from "../../componentes/context/AppContext";
import Dialog from "../../componentes/dialog";
import { confirmAlert } from 'react-confirm-alert'; 
import { FaSistrix } from "react-icons/fa";
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import { listaAniversariantes } from "../../api/DataApi";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel from "../../componentes/carousel";

const LoginPagina = () => {
    const usuarioLogado = useContext(AppContext);
    const [usuario, setUsuario] = useState();
    const [senha, setSenha] = useState();
    const [openAlterarSenha, setOpenAlterarSenha] = useState(false);
    const [id, setId] = useState();
    const [aniversariantes, setAniversariantes] = useState();

    const [novaSenha, setNovaSenha] = useState({
        senha: "",
        confirma: ''
    })

    const [validacaoSenha, setValidacaoSenha] = useState({
        validaSenha: null,
        validaConfirma: null
    });

    const navigate = useNavigate();

    const onEntrar = () => {
        login(usuario, senha, callBackLogin);
    }

    const callBackLogin = (response) => {
        console.log("callBackLogin usuario", response);

        if (response) {
            if (response.codigo === 0) {
                setId(response.usuario.id);

                retornaPerfilPeloId(response.usuario.idPerfil, (responsePerfil) => {
                    console.log("callBackLogin", responsePerfil);

                    if (responsePerfil) {
                        response.listaFuncoes = responsePerfil.listaFuncoes;

                        if (response.usuario.situacao === -1) {
                            setOpenAlterarSenha(true);
                            setNovaSenha({
                                senha: "",
                                confirma: ""
                            });
                        }
                        else if (response.usuario.situacao === 0) {
                            confirmAlert({
                                title: "Atenção",
                                message: "Conta desativada",
                                buttons: [
                                    {
                                        label: "Fechar"
                                    }
                                ]
                            });
                        }
                        else {
                            response.logado = true;

                            usuarioLogado.setUsuario(response);

                            navigate('../home');
                        }
                    }
                })
            }
            else {
                confirmAlert({
                    title: "Atenção",
                    message: response.mensagem,
                    buttons: [
                        {
                            label: "Fechar"
                        }
                    ]
                });
            }
        }
        else {
            confirmAlert({
                title: "Atenção",
                message: "Houve um erro na autenticação. Entre em contato com o suporte",
                buttons: [
                    {
                        label: "Fechar"
                    }
                ]
            });
        }
    }

    const onChangeUsuario = (e) => {
        setUsuario(e.target.value);
    }

    const onChangeSenha = (e) => {
        setSenha(e.target.value);
    }

    const onSalvar = () => {
        if (!novaSenha.senha || novaSenha?.senha.trim() === "") {
            setValidacaoSenha({...validacaoSenha, validaSenha: "Necessário informar a senha"});

            return false;
        }

        if (!novaSenha.confirma || novaSenha?.confirma.trim() === "") {
            setValidacaoSenha({...validacaoSenha, validaConfirma: "Necessário informar a confirmação da senha"});

            return false;
        }     
        
        if (novaSenha.senha !== novaSenha.confirma) {
            setValidacaoSenha({...validacaoSenha, validaConfirma: "Confirmação da senha não confere"});

            return false;            
        }

        setValidacaoSenha({
            validaSenha: null,
            validaConfirma: null
        });  
        
        alterarSenha(id, novaSenha.senha, (response) => {
            if (response) {
                setOpenAlterarSenha(false);

                confirmAlert({
                    title: 'Confirmação',
                    message: 'Senha altera com sucesso',
                    buttons: [
                      {
                        label: 'Sair',
                        onClick: () => onSairConfirmacao()
                      }
                    ]
                  });
            }
        })
    }

    const onSairConfirmacao = () => {
        setSenha("");
        setUsuario("");
    }

    const onChangeValue = (e) => {
        setNovaSenha({...novaSenha, [e.target.name]: e.target.value});

        switch(e.target.name) {
            case "senha":
                setValidacaoSenha({...validacaoSenha, validaSenha: null});
                break;

            case "confirma":
                setValidacaoSenha({...validacaoSenha, validaConfirma: null});
                break;
        }
    }

    useEffect(() => {
        listaAniversariantes(callBackListaAniversariantes);
    }, []);

    const callBackListaAniversariantes = (response) => {
        if (response)
            setAniversariantes(response.listaAniversariantes);
    }

    return (
        <>
        <div className="flex flex-col w-full">
            <div className="flex flex-row w-full bg-caixa items-center" style={{height: "80px"}}>
                <div className="flex flex-row w-full items-center justify-between">
                    <div className="flex flex-col justify-center ml-5">
                        <img src={LogoCaixa} style={{width: "130px"}}></img>
                    </div>

                    <div className="search-box">
                        <input type="text" placeholder="buscar" className="search-txt" />
                        <a href="#">
                            <FaSistrix size={14} className="mr-3 ml-3"></FaSistrix>
                        </a>
                    </div>

                    <div className="flex flex-col mr-6 div_login">
                        <div className="flex flex-row justify-between items-center">
                            <label htmlFor="tbxEmail" className="text-white mr-2">Usuário:</label>
                            <input value={usuario} onChange={(e) => onChangeUsuario(e)} type="text" id="tbxEmail"></input>
                        </div>

                        <div className="flex flex-row justify-between items-center mt-1">
                            <label htmlFor="tbxSenha" className="text-white mr-2">Senha:</label>

                            <div className="flex flex-col">
                                <input value={senha} onChange={(e) => onChangeSenha(e)} type="password" id="tbxSenha"></input>
                            </div>
                        </div>

                        <div className="flex flex-row justify-end items-center">
                            <button className="self-end mt-1" style={{width: "70px"}} onClick={onEntrar}>Entrar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-row w-full h-full">
                <div className="flex flex-col w-52 bg-caixa_barra p-2">
                    {/*
                    <ProSidebar className="pro-sidebar-login">
                        <SidebarContent>
                            <Menu className="text-white">
                                <SubMenu title="Portal do RH">
                                    <MenuItem>
                                        Agendamento de Férias
                                    </MenuItem>
                                    <MenuItem>
                                        Registro de Pontos
                                    </MenuItem>  
                                    <MenuItem>
                                        Denúncia RH
                                    </MenuItem>  
                                    <MenuItem>
                                        Folha de Pagamento
                                    </MenuItem>    
                                    <MenuItem>
                                        Informe de Rendimento
                                    </MenuItem>                                                                                                          
                                </SubMenu>

                                <SubMenu title="Documentos">
                                </SubMenu>

                                <SubMenu title="Informações do Produto">
                                </SubMenu>

                                <SubMenu title="Fotos">
                                </SubMenu>  

                                <SubMenu title="Custura da Empresa">
                                </SubMenu>    

                                <SubMenu title="Treinamentos">
                                </SubMenu>  

                                <SubMenu title="Cursos Obrigatórios">
                                </SubMenu>   

                                <SubMenu title="Lista de Colaboradores">
                                </SubMenu>    

                                <SubMenu title="Suporte de TI">
                                </SubMenu>                                                                                                                                                                                        
                            </Menu>
                        </SidebarContent>
                    </ProSidebar>
                    */}
                </div>

                <div className="flex flex-col w-full p-4">
                    <div style={{width: "100%"}}>
                        <Carousel/>
                    </div>

                    <div className="flex flex-row justify-between">
                        <div className="div-container">
                            <h1 className="mb-3">Aniversariantes do Mês</h1>
                            <div className="flex flex-col" style={{ height: "12vh", overflow: "auto" }}>
                                {aniversariantes && aniversariantes.map(c => {
                                    return (
                                        <div className="flex flex-row justify-between">
                                            <div>{c.nome}</div>
                                            <div>{c.dia}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>

        <Dialog open={openAlterarSenha}
                title="Primeiro acesso"
                onClose={() => setOpenAlterarSenha(false)}
                primaryButtonText="Salvar"
                onPrimaryButtonClick={onSalvar}
                width="500px">
            <div className="flex flex-col w-full">
                <label htmlFor="tbxSenha">Senha</label>
                <input value={novaSenha.senha} name="senha" id="tbxSenha" type="password" onChange={onChangeValue}></input>
                <div>
                    {validacaoSenha.validaSenha && <span style={{fontSize: "10px"}} className="text-red-500">{validacaoSenha.validaSenha}</span>}
                </div>

                <label htmlFor="tbxConfirma">Conirma senha</label>
                <input value={novaSenha.confirma} name="confirma" id="tbxConfirma" type="password" onChange={onChangeValue}></input>
                <div>
                    {validacaoSenha.validaConfirma && <span style={{fontSize: "10px"}} className="text-red-500">{validacaoSenha.validaConfirma}</span>}
                </div>                
            </div>
        </Dialog>        
        </>
    )
}

export default LoginPagina;