import React, {useState} from "react";
import {login} from "../../api/DataApi";
import { useNavigate } from "react-router-dom";
import LogoCaixao from '../../imagens/caixa_assistencia_2d_vertical_negativa.png';

const TelaInicial = () => {
    const navigate = useNavigate();

    const versao1 = () => {
            navigate('../intranet');   
    }

    const versao2 = () => {
        window.location.href="https://polaris.caixaassistencia.com.br";
    }

    return (
        <div className="flex fle-col items-center justify-center h-screen">
            <div className="flex flex-col bg-caixa h-96 w-95 rounded-2xl shadow-lg">
                <img src={LogoCaixao} className="self-center mt-5" style={{width: "150px"}}></img>
                <span className="mb-6"></span>     
            <div className="flex flex-col items-center justify-center h-full">
                <i class="gg-info" style={{width: "50px", height:"50px"}}></i>
                <div>
                    <span className="text-white text-Futura" style={{fontSize: "30px"}}>Versão da intranet</span>
                </div>
                <span className="mt-2"></span>
                
                    <div className="flex flex-col w-150 h-auto  ml-4  mr-4">   
                        <span className="text-white text-Futura ml-4  mr-4" style={{fontSize: "15px",}}>Olá, já está disponível a versão 2.0 (beta) da intranet. Gostaria de conhecer?</span>
                        <span className="mt-10">
                            <button className="w-60 h-10 btn-laranja ml-4  mr-4" onClick={() => versao1(null)}
                            >Ficar na versão 1.0</button>
                            <button className="w-60 h-10  btn-verde ml-4  mr-4 " onClick={() => versao2(null)}>Ir para versão 2.0 (beta)</button>
                        </span>
                        <span className="mb-10"></span>                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TelaInicial;