import ReactModal from 'react-modal';
import { MdClose } from 'react-icons/md';

const Dialog = ({
    open, 
    title, 
    description, 
    onClose, 
    onPrimaryButtonClick, 
    onSecondaryButtonClick, 
    primaryButtonText, 
    secondaryButtonText, 
    disablePrimaryButton, 
    deleteButton,
    onDeleteClick,
    width, 
    warning,
    footer,
    children,
    primaryButtonVisible = true,
    saving = false,
    disableOnWarning = true
}) => {
    const style = {
        width: width ? width : '',
    }
    return (
        <ReactModal
            isOpen={open}
            onRequestClose={onClose}
            contentLabel="Dialog"
            className="dialog flex flex-col justify-between"
            overlayClassName="dialog-overlay"
            ariaHideApp={false}
        >
            {(title || description) &&
                <div className="flex flex-row">
                    <div className="flex flex-col w-full mb-4">
                        {title && <span className="text-xl font-bold">{title}</span>}
                        {description && <span className="text-gray-500">{description}</span>}
                    </div>
                    <div onClick={onClose} className="flex flex-row items-center justify-center text-gray-400 w-8 h-8 hover:bg-gray-100 rounded-full cursor-pointer">
                        <MdClose size={20} />
                    </div>
                </div>
            }
            <div className="dialog-content" style={width ? style : undefined}>
                {children}
            </div>
            {!footer ? 
                ((primaryButtonText && onPrimaryButtonClick) || secondaryButtonText || (deleteButton && onDeleteClick)) &&
                    <div className={`flex flex-row w-full mt-4 ${(deleteButton && onDeleteClick) || warning ? "justify-between": "justify-end"}`}>
                        {deleteButton && onDeleteClick &&
                            <button onClick={onDeleteClick} className="button-secondary mx-2 text-red-500">
                                Delete
                            </button>
                        }
                        {warning &&
                            <div className="text-red-500 font-bold h-4 p-2">{warning}</div>
                        }
                        <div>
                            {secondaryButtonText && 
                                <button onClick={onSecondaryButtonClick ? onSecondaryButtonClick : onClose} className="button-secondary mx-2">
                                    {secondaryButtonText}
                                </button>
                            }
                            {((primaryButtonText && onPrimaryButtonClick && primaryButtonVisible) && !saving) ?
                                <button onClick={onPrimaryButtonClick} disabled={disablePrimaryButton || (disableOnWarning && warning) ? true : false} className="button-primary">
                                    {primaryButtonText}
                                </button>
                                :
                                <></>
                            }
                        </div>
                    </div>
            :
                <>
                    {footer}
                </>
            }
        </ReactModal>
    );
}

export default Dialog;