

import React, { Component } from "react";
import Chart from "react-apexcharts";

export default function RadialBarMultiplos({regioes,porcentagem}) {
    class BarDistributed extends Component {
      constructor(props) {
        super(props);

        var colors = [
          '#008FFB',
          '#529F51',
          '#FF7F27',
          '#6000BF',
          '#ED1C24',
          '#8080FF',
          '#FF0080',
          '#D10CE8',
          '#408080',
        ]
    
        this.state = {
          
          series: [...porcentagem],
          options: {
            chart: {
              height: 480,
              type: 'radialBar',
            },
            plotOptions: {
              radialBar: {
                offsetY: 0,
                startAngle: 0,
                endAngle: 270,
                hollow: {
                  margin: 5,
                  size: '10%',
                  background: 'transparent',
                  image: undefined,
                },
                dataLabels: {
                  name: {
                    show: false,
                  },
                  value: {
                    show: false,
                  }
                }
              }
            },
            colors: colors,
            labels: [...regioes],
            legend: {
              show: true,
              floating: true,
              fontSize: '40%',
              position: 'left',
              offsetX: 100,
              offsetY: 12,
              labels: {
                useSeriesColors: true,
              },
              markers: {
                size: 0
              },
              formatter: function(seriesName, opts) {
                return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]+"%"
              },
              itemMargin: {
                vertical: 5
              }
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                    show: false
                }
              }
            }]
          },
        
        
        };
      }
      render() {
        return (
          <Chart
                options={this.state.options}
                series={this.state.series}
                type="radialBar"
                height="88%"
                width="150%"
              />
        );
      }
    }
   
  return (
    <div className="flex flex-col w-full">
        <div className="flex flex-col">
          <div className="flex flex-row h-full">
            <div className="w-full" style={{ height: "calc(100vh - 50px)", overflow: "hidden" }}>
              <BarDistributed/>
            </div>
          </div>
        </div>
      </div>
  )
}


