import React, {useEffect, useState} from "react";
import LogadoLayout from "../../componentes/logado";
import { FiArrowDown, FiLayers, FiArrowUp } from "react-icons/fi";
import { listaNormativos,downloadNormativoAPI } from "../../api/DataApi";

export default function CriarNormativos() { 

    const [loading, setLoading] = useState(false);
    const [normativos, setNormativos] = useState([]);
    const [qtde, setQtde] = useState(0);


    useEffect(() => {
        listaNormativos(callBackPesquisarNormativos);
    }, []);

    const callBackPesquisarNormativos = (response) => {
        console.log("Pesquisar", response);

        if (response) {
            setQtde(response.quantidade);
            setNormativos(response.normativos);
        }

        setLoading(false);
    }

    const downloadNormativo = (idArquivo) => {
        console.log(idArquivo)
        downloadNormativoAPI(idArquivo, callBackDownload)
    }

    const callBackDownload = (response) => {

        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.target = "_blank"
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
        //let pdfWindow = window.open("");
        //pdfWindow.document.write("<iframe width='100%' height='100%' src='" + encodeURI(base) + "'></iframe>");

    }
    function titleString(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }

    return (
        <>
            <LogadoLayout titulo="Normativos" icon={<FiLayers size={30}/>}>
                <div className="flex justify-between mt-4">
                    <div className="flex-col w-full p-4">
                        <div>
                            <table>
                                <tbody>
                                    {normativos && normativos.map((normativos, index) => {
                                        return (
                                            <tr key={index}>
                                                <td align="left">
                                                    <span className="cursor-pointer text-xl text-primary" onClick={(e) => downloadNormativo(normativos.idArquivo)}>
                                                            {titleString(normativos.txNomeArquivo)}
                                                        </span> 
                                                    </td>
                                            </tr>

                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </LogadoLayout>  
        </>
    )
}