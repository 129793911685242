

import React, { useState, Component } from "react";
import Chart from "react-apexcharts";

export default function BarDistributed({regioes,totais}) {
const [vendas, setVendas] = useState([]);
const [loading, setLoading] = useState(false);

    class BarDistributed extends Component {
      constructor(props) {
        super(props);

        var colors = [
          '#008FFB',
          '#529F51',
          '#FF7F27',
          '#6000BF',
          '#ED1C24',
          '#8080FF',
          '#FF0080',
          '#D10CE8',
          '#408080',
        ]
    
        this.state = {
        
          series: [{
            data: [...totais]
          }],
          options: {
            chart: {
              height: 350,
              type: 'bar',
              events: {
                click: function(chart, w, e) {
                }
              }
            },
            colors: colors,
            plotOptions: {
              bar: {
                columnWidth: '50%',
                distributed: true,
              }
            },
            dataLabels: {
              enabled: true,
              categories: [...regioes],
              style: {

                  fontSize: '35px'
                }
              
            },
            legend: {
              show: false
            },
            xaxis: {
              categories: [...regioes],
              labels: {
                style: {
                  colors: colors,
                  fontSize: '18px',
                }
              }
            }
          },
        };
      }
      render() {
        return (
          <Chart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                height="80%"
                width="100%"
              />
        );
      }
    }
   
  return (
    <div className="flex flex-col w-full">
        <div className="flex flex-col">
          <div className="flex flex-row h-full">
            <div className="w-full" style={{ height: "calc(100vh - 50px)", overflow: "hidden" }}>
              <BarDistributed/>
            </div>
          </div>
        </div>
      </div>
  )
}


