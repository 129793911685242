import React, { useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton';
import { listaRelatorioSIGPFRegiao, telasAtivas } from "../../../api/DataApi";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';
import MenuPainel from "../componentes/menuPainel"
import SpilineChart from '../../../componentes/charts/splineChart';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import moment from 'moment';



registerLocale('ptBR', ptBR);

export default function VendasPorRegiao() {
  const [telas, setTelas] = useState([]);
  const [vendas, setVendas] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    telasAtivas(callBackTelasAtivas)
  }, []);

  useEffect(() => {
    setTimeout(() => {
      nextTela()
    }, 60000);
  }, []);

  const callBackTelasAtivas = (response) => {
    setLoading(false);
    if (response) {
      setTelas(response.telasAtivas);

    }
  }

function nextTela() {
  {
    let num = telas.length;
    let id = parseInt(location.state.id);

    let indice;
    setTelas((state) => {
      state.map((item, i) => {
        if (id >= num) {
          i = 0
          id = 0
        }
        if (i == id) {
          const { url } = item;
          indice = i++;
          return (
            navigate('../conversao',{state:{id:1,name:'sabaoon'}})
          )
        }
      })
    });
  }

}

  useEffect(() => {
    listaRelatorioSIGPFRegiao(callBackSIGPFRegiao);
  }, []);

  let date_create = moment().format("DD-MM-YYYY")

  const callBackSIGPFRegiao = (response) => {
    setLoading(false);
    if (response) {
      setVendas(response.sigpfPlano);
    }
  }

  const totais = (vendas.map(venda => {
    return (
        venda.total_Vendido
    )
  })
)
  const regioes = (vendas.map(venda => {
    
    return (
        venda.plano
    )
  })
  )

  let total_planos = 0;
  const totais_planos = (vendas.map(venda => {
    total_planos = total_planos + venda.total_Vendido;
    return (
        venda.total_Vendido
    )
  })
)

  return (
    <>
      <div className="flex flex-col" style={{ height: "calc(100vh)", overflow: "hidden" }}>
      {telas.map((item, i) => {
          const { descricao, nome } = item;
          if (nome == "VendasPorRegiao") {
            return (
              <MenuPainel titulo={descricao} texto={total_planos} font={"h1-painel-conversao"}/>
            )
          }
        })
        }
        <div className="w-full" style={{ height: "calc(100vh)", }}>
            <div className="flex flex-col w-full">
              <div className="overflow-auto p-5">
                {loading ?
                  <Skeleton count={60} height={"30px"} />
                  :
                  <SpilineChart regioes={regioes} totais={totais}
                 />
                }
              </div>
            </div>

          </div>
        
      </div>
    </>
  )
}