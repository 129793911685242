import React, {useEffect, useState} from "react";
import LogadoLayout from "../../componentes/logado";
import { FiArrowDown, FiLayers, FiArrowUp } from "react-icons/fi";
import { listaNormativos, cadastrarNormativo, downloadNormativoAPI, excluirNormativo } from "../../api/DataApi";
import Dialog from "../../componentes/dialog";


export default function EditarNormativos() { 

    const [loading, setLoading] = useState(false);
    const [normativos, setNormativos] = useState([]);
    const [qtde, setQtde] = useState(0);
    const [adicionarArquivo, setAdicionarArquivo] = useState(false);
    const [checked, setChecked] = useState([]);
    const [remocaoArquivo, setRemocaoArquivo] = useState(false);
    const [validacao, setValidacao] = useState(null);

    const [filtro, setFiltro] = useState({
        txNomeArquivo: "",
        Arquivo: null,
        idArquivo: 0
    });

    useEffect(() => {
        listaNormativos(callBackPesquisarNormativos);
    }, []);

    const callBackPesquisarNormativos = (response) => {
        console.log("Pesquisar", response);

        if (response) {
            setQtde(response.quantidade);
            setNormativos([]);
            setNormativos(response.normativos);
        }

        setLoading(false);
    }

    const callBackCNormativos = (response) => {
        console.log("Cadastrar", response);

        if (response) {
            setAdicionarArquivo(false)
            listaNormativos(callBackPesquisarNormativos);
        }

        setLoading(false);
    }

    const callBackRemoverNormativos = (response) => {

        if (response) {
            setRemocaoArquivo(false)
            listaNormativos(callBackPesquisarNormativos);
            setChecked([]);
        }

        setLoading(false);
    }

    const showAdicionarArquivo = (venda) => {

        if (venda) {
            setAdicionarArquivo(true);
        }
    }

    const confimacaoRemocao = () => {
        if (checked.length > 0) {
            setRemocaoArquivo(true);
        }
    }

    const onAtualizaFiltro = (e) => {
        setFiltro({...filtro, [e.target.name]: e.target.value});
    }

    const onAtualizaFile = (e) => {
        if(e[0].type === "application/pdf") {
            filtro.Arquivo = e[0];
            setValidacao("");
        } else {
            setValidacao("Formato do arquivo inválido.")
        }
    }

    const onSalvar = () => {
        if(filtro.Arquivo !== null && filtro.txNomeArquivo !== "") {
            const formData = new FormData();
            formData.append("Arquivo", filtro.Arquivo);
            formData.append("txNomeArquivo", filtro.txNomeArquivo);
            formData.append("idArquivo", filtro.idArquivo);

            setValidacao("");
            cadastrarNormativo(formData, callBackCNormativos)
        } else {
            setValidacao("Nome da normativa e Arquivo e de preenchimento obrigatório.")
        }
    }

    const onRemover = () => {
        excluirNormativo(checked, callBackRemoverNormativos)
    }

    const downloadNormativo = (idArquivo) => {
        console.log(idArquivo)
        downloadNormativoAPI(idArquivo, callBackDownload)
    }

    const callBackDownload = (response) => {

        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.target = "_blank"
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
        //let pdfWindow = window.open("");
        //pdfWindow.document.write("<iframe width='100%' height='100%' src='" + encodeURI(base) + "'></iframe>");

    }

    const handleCheck = (event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
          updatedList = [...checked, Number(event.target.value)];
        } else {
          updatedList.splice(checked.indexOf(Number(event.target.value)), 1);
        }
        setChecked(updatedList);
      };
      function titleString(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }

    return (
        <>
            <LogadoLayout titulo="Normativos" icon={<FiLayers size={30}/>}>
                <div className="text-right">
                    <button className="w-28 bg-green-300 mr-4" onClick={showAdicionarArquivo}>Adicionar</button>
                    <button className="w-28 bg-red-300 mr-4" onClick={confimacaoRemocao} disabled={checked.length === 0}>Remover</button>
                </div>
                <div className="flex justify-between mt-4">
                    <div className="flex-col w-full p-4">
                        <div>
                            <table>
                                <tbody>
                                    {normativos && normativos.map((normativos, index) => {
                                        return (
                                            <tr key={index}>
                                                <td align="left">
                                                    <input value={normativos.idArquivo} checked={checked.findIndex(n => n === normativos.idArquivo) !== -1} type="checkbox" onChange={handleCheck} />
                                                </td> 
                                                <td align="left">
                                                    <span className="cursor-pointer text-xl text-primary" onClick={(e) => downloadNormativo(normativos.idArquivo)}>
                                                        {titleString(normativos.txNomeArquivo)}
                                                    </span> 
                                                </td>
                                            </tr>

                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </LogadoLayout> 

            <Dialog open={adicionarArquivo}
                    title={
                        <>
                            <div className="flex flex-row">
                            <div>Adicionar arquivo</div>                    
                            </div>
                        </>
                    }
                    onClose={() => setAdicionarArquivo(false)}
                    primaryButtonText="Salvar"
                    secondaryButtonText="Cancelar"
                    width="650px"
                    onPrimaryButtonClick={onSalvar}
                    >
                <div>
                    <div className="mr-3">Nome da normativa: </div>
                    <div className="mr-6">
                        <input type="text" 
                                className="w-full" 
                                name="txNomeArquivo" 
                                value={filtro.txNomeArquivo}
                                onChange={(e) => onAtualizaFiltro(e)}/>
                    </div>

                    <div className="mr-3 mt-4">Arquivo: </div>
                    <div className="mr-6">
                        <input type="file" 
                                className="w-full" 
                                name="Arquivo"
                                accept=".pdf"
                                onChange={(e) => onAtualizaFile(e.target.files)}/>
                    </div>
                    <div>
                        {validacao && <span className="text-red-500">{validacao}</span>}
                    </div>
                </div>
            </Dialog>

            <Dialog open={remocaoArquivo}
                    title={
                        <>
                            <div className="flex flex-row">
                                <div>Conformação</div>                    
                            </div>
                        </>
                    }
                    onClose={() => setRemocaoArquivo(false)}
                    secondaryButtonText="Cancelar"
                    primaryButtonText="Aceitar"
                    onPrimaryButtonClick={onRemover}>
                <div>
                    <span>Deseja remover as normativas?</span>
                </div>
                
            </Dialog>
        </>
    )
}