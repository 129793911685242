import React, {useState} from "react";
import { Routes, Route } from "react-router-dom";
import EscolhaIntranet from "./paginas/escolha";
import LoginPagina from "./paginas/login";
import HomePagina from "./paginas/home";
import UsuarioPagina from "./paginas/usuario";
import VendasPagina from "./paginas/vendas";
import CargaPagina from "./paginas/carga";
import RelatorioPagina from "./paginas/relatorio"
import PerfilPagina from "./paginas/perfil";
import { AppContext } from "./componentes/context/AppContext";
import VendasUnidadesPagina from "./paginas/vendasUnidades";
import VendasUnidadesAgrupadasPagina from "./paginas/vendasUnidadesAgrupadas";
import CriarNormativos from "./paginas/normativos";
import EditarNormativos from "./paginas/normativos/editar";
import RelatorioTaspjunc from "./paginas/relatorioTaspjunc";
import TelaPainelPagina from "./paginas/painel/configlistatelas";
import PainelPagina from "./paginas/painel/painel";
import TotalVendasDia from "./paginas/painel/telas/totalVendasDia";
import Conversao from "./paginas/painel/telas/conversao";
import VendasDiaRegiao from "./paginas/painel/telas/vendasDiaRegiao";
import VendasPorRegiao from "./paginas/painel/telas/vendasPorRegiao";
import EfetividadeRegiao from "./paginas/painel/telas/efetividadeRegiao";

export default function Rotas() {
    const [usuario, setUsuario] = useState();

    return (
        <AppContext.Provider value={{usuario, setUsuario}}>
            <Routes>
                <Route path="/" element={<EscolhaIntranet/>} />
                <Route path="intranet" element={<LoginPagina/>} />
                <Route path="home" element={<HomePagina />} />
                <Route path="usuario" element={<UsuarioPagina />} />
                <Route path="vendas" element={<VendasPagina />} />
                <Route path="carga" element={<CargaPagina />} />
                <Route path="relatorio" element={<RelatorioPagina />} />
                <Route path="perfil" element={<PerfilPagina />} />
                <Route path="vendasUnidades" element={<VendasUnidadesPagina />} />
                <Route path="vendasUnidadesAgrupadas" element={<VendasUnidadesAgrupadasPagina />} />
                <Route path="normativos" element={<CriarNormativos />}/>
                <Route path="normativos/editar" element={<EditarNormativos />}/>
                <Route path="relatorioTaspjunc" element={<RelatorioTaspjunc />}/>
                <Route path="telas" element={<TelaPainelPagina />} />  
                <Route path="totalVendasDia" element={<TotalVendasDia />} />
                <Route path="vendasDiaRegiao" element={<VendasDiaRegiao />} />
                <Route path="vendasPorRegiao" element={<VendasPorRegiao />} />
                <Route path="conversao" element={<Conversao />} />
                <Route path="painel" element={<PainelPagina />} />
                <Route path="efetividadeRegiao" element={<EfetividadeRegiao />} />
            </Routes>
        </AppContext.Provider>
    )
}