import React, { useEffect, useState } from "react";
import { conversao, telasAtivas } from "../../../api/DataApi";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';
import RadialBar from '../../../componentes/charts/radialBar';
import MenuPainel from "../componentes/menuPainel"
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";



registerLocale('ptBR', ptBR);

export default function Conversao() {
  const [telas, setTelas] = useState([]);
  const [convertido, setTotal] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    telasAtivas(callBackTelasAtivas)
  }, []);

  useEffect(() => {
    setTimeout(() => {
      nextTela()
    }, 60000);
  }, []);

  const callBackTelasAtivas = (response) => {
    setLoading(false);
    if (response) {
      setTelas(response.telasAtivas);
    }

  }

  function nextTela() {
    {
      let num = telas.length;
      let id = parseInt(location.state.id);

      let indice;
      setTelas((state) => {

        state.map((item, i) => {
          if (id >= num) {
            i = 0
            id = 0
          }
          if (i == id) {
            const { url } = item;
            indice = i++;
            return (
              navigate('../totalVendasDia', { state: { id: 1, name: 'sabaoon' } })
            )
          }
        })
      });
    }
  }

  useEffect(() => {
    conversao(callBackConversao);
  }, []);

  const callBackConversao = (response) => {
    setLoading(false);
    if (response) {
      setTotal(response.convertido);
    }
  }

  return (
    <>
      <div className="flex flex-col" style={{ height: "calc(100vh )", overflow: "hidden" }}>
        {telas.map((item, i) => {
          const { descricao, nome } = item;
          if (nome == "Conversão") {
            return (
              <MenuPainel titulo={descricao} />
            )
          }
        })
        }
        <div className="w-full" style={{ height: "calc(100vh 10px)", overflow: "hidden" }}>
          {loading ?
            <Skeleton count={60} height={"30px"} />
            :
            <RadialBar key={convertido} porcentagem={convertido} />
          }
        </div>
      </div>
    </>
  )
}