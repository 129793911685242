import React, {useEffect, useState} from "react";
import Skeleton from 'react-loading-skeleton';
import LogadoLayout from "../../componentes/logado";
import { listaCarga } from "../../api/DataApi";
import { FiMonitor, FiRotateCcw } from "react-icons/fi";
import {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';
import moment from 'moment';
registerLocale('ptBR', ptBR);


export default function CargaPagina() {
    const [loading, setLoading] = useState(false);
    const [carga, setCarga] = useState([]);
    const [horaCarregamento, setHoraCarregamento] = useState({
        ticks: 0,
        hora: 0,
        minutos: 0,
        situacao: "-1"
    });

    useEffect(() => {
        listaCarga(callBackListaCarga);
    }, []);


    const onPesquisa = () => {
        setLoading(loading);
        listaCarga(callBackListaCarga);
    }

    function pad2(number){
        number = '0' + number;
        return number.substr(number.length - 2);
     }

    function horasMinutos(ticks) {
        var seconds = ticks / 1000; 
        var hour = Math.floor((seconds / 3600) / 10000);
        if(hour < 10){
            hour = '0'+hour;
        }
        var minute = Math.floor((ticks / 60) % 60);
        var second = seconds % 60;
        
        var result = hour + ':' + pad2(minute) + ':' + pad2(second)
        return result;
    }

    const callBackListaCarga = (response) => {
        if (response) {
            setCarga(response.carga);
        }
        setLoading(false);
    }

    return (
        <>      
            <LogadoLayout titulo="Carga"
                          icon={<FiMonitor size={30}/>}
                          opcao={
                            <button className="flex flex-row p-4 items-center justify-between" style={{height: 43, width: "8rem"}} onClick={onPesquisa}>
                            <FiRotateCcw size={15}/>
                            Atualizar Carregamento
                        </button> }>

                    <div className="overflow-auto p-5">
                        {loading ?
                            <Skeleton count={60} height={"30px"}/>
                        :
                            <>
                            {carga && carga.length > 0 ?
                                <div className="flex flex-col">
                                    
                                    <table cellPadding={2} cellSpacing={2} border={0}>
                                        <thead>
                                            <th align="left">Tipo do Arquivo</th>
                                            <th align="left">Status do Carregamento</th>
                                            <th align="left">Data do Carregamento</th>
                                            <th align="left">Hora do Carregamento</th>
                                            <th align="left">Banco de Dados</th>
                                            
                                        </thead>
                                        <tbody>
                                            {carga && carga.map((carga, index) => {
                                                return (
                                                    <tr key={index} className="bg-white hover:bg-gray-100 cursor-pointer" >
                                                        <td align="left">{carga.tipoArquivo}</td>
                                                        <td align="left">{carga.statusCarregamento}</td>
                                                        <td align="left">{moment(carga.dataCarregamento).format("DD/MM/YYYY")}</td>
                                                        <td align="left">{""+horasMinutos(carga.horaCarregamento.ticks)}</td>
                                                        <td align="left">{carga.base}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            :
                                <div><b>Não consta nenhuma informação</b></div>
                            }
                            </>
                        }
                    </div>
            </LogadoLayout>
        
        </>
    )
}