import React, {useEffect, useState} from "react";
import Cabecalho from "../../componentes/cabecalho";
import MenuPage from "../../componentes/menu";
import { listaUsuarios, listaPerfil, retornaUsuarioPeloId, salvarUsuario, resetarSenha } from "../../api/DataApi";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { FiPlus } from "react-icons/fi";
import Dialog from "../../componentes/dialog";
import { AiOutlineUser, AiOutlineUsergroupAdd } from "react-icons/ai";
import LogadoLayout from "../../componentes/logado";

export default function UsuarioPagina() {
    const [usuarios, setUsuarios] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openModalUsuario, setOpenModalUsuario] = useState(false);
    const [perfil, setPerfil] = useState();

    const [usuario, setUsuario] = useState({
        id: "",
        nick: "",
        email: "",
        idperfil: "",
        celular: "",
        telefone: "",
        situacao: -1
    });

    useEffect(() => {
        Pesquisa("");
    }, []);

    const Pesquisa = (pesquisa, loading = true) => {
        setLoading(loading);

        listaUsuarios(pesquisa, callBackListaUsuarios);
    }

    const callBackListaUsuarios = (response) => {
        setLoading(false);

        if (response) {
            console.log(response);
            setUsuarios(response.listaUsuarios);
        }
    }

    const onOpenModalUsuario = (id) => {
        listaPerfil(callBackListaPerfil);

        if (id) {
            retornaUsuarioPeloId(id, callBackRetornaUsuario);
        }
        else {
            setUsuario( {
                id: "",
                nick: "",
                email: "",
                idperfil: "",
                celular: "",
                telefone: "",
                situacao: -1
            } );

            setOpenModalUsuario(true);
        }

    }

    const callBackRetornaUsuario = (response) => {
        console.log("callBackRetornaUsuario", response);
        
        if (response) {
            setUsuario({
                id: response.usuario.id,
                nick: response.usuario.nick,
                nome: response.usuario.nome,
                email: response.usuario.email,
                idperfil: response.usuario.idPerfil,
                celular: response.usuario.celular,
                telefone: response.usuario.telefone,
                situacao: response.usuario.situacao    
            });

            setOpenModalUsuario(true);            
        }
    }

    useEffect(() => {
        console.log("usuario", usuario);
    }, [usuario])

    const callBackListaPerfil = (response) => {
        console.log(response);

        if (response) {
            setPerfil(response.listaPerfil);
        }
    }

    const onSalvar = () => {
        salvarUsuario(usuario, callBackSalvarUsuario);
    }

    const callBackSalvarUsuario = (response) => {
        if (response && response?.codigo === 0) {
            setOpenModalUsuario(false);

            Pesquisa("");
        }
        console.log("Salvar Usuário:", response);
    }

    const onChangeValue = (e) => {
        console.log("onChangeValue", e.target.name, e.target.value);

        setUsuario({...usuario, [e.target.name]: e.target.value});
    }

    const onResetar = (id) => {
        resetarSenha(id, (response) => {
            if (response && response?.codigo === 0) {
                setOpenModalUsuario(false);

                Pesquisa("");
            }
        })
    }

    useEffect(() => {
        if (perfil)
            console.log(perfil);
    }, [perfil])

    return (
        <>
        <LogadoLayout
            titulo="Cadastro de Usuários"
            icon={<AiOutlineUser size={30}/>}
            opcao={
                <button className="flex flex-row p-4 items-center justify-between" onClick={() => onOpenModalUsuario(null)}>
                    <FiPlus size={15}/>
                    Novo Usuário
                </button> }>
            <div className="flex flex-col w-full">
                <div className="ml-4 mb-4 mt-4">
                    <input type="text" placeholder="Pesquisar" onChange={(e) => Pesquisa(e.target.value, false)}></input>
                </div>

                <div className="overflow-auto p-5">
                    {loading ?
                        <Skeleton count={60} height={"30px"}/>
                    :
                        <div className="flex flex-col">
                            <table cellPadding={2} cellSpacing={2} border={0}>
                                <thead>
                                    <th></th>
                                    <th align="left">ID</th>
                                    <th align="left">Email</th>
                                    <th align="left">Nome</th>
                                    <th align="left">Perfil</th>
                                    <th align="left">Telefone</th>
                                    <th align="left">Celular</th>
                                    <th align="center">Situação</th>
                                </thead>
                                <tbody>
                                    {usuarios && usuarios.map(usuario => {
                                        return (
                                            <tr className="cursor-pointer bg-white hover:bg-gray-100" onClick={() => onOpenModalUsuario(usuario.id)}>
                                                <td>
                                                    <div className="flex flex-row rounded-full bg-gray-300 w-10 h-10 justify-center items-center text-black border-gray-500 border-2">
                                                        <div>
                                                            {usuario.nick.substr(0,1).toUpperCase()}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td align="left">{usuario.nick}</td>
                                                <td align="left">{usuario.email}</td>
                                                <td align="left">{usuario.nome}</td>
                                                <td align="left">{usuario.perfil}</td>
                                                <td align="left">{usuario.telefone}</td>
                                                <td align="left">{usuario.celular}</td>
                                                <td align="center">
                                                    {
                                                        usuario.situacao === -1 ?
                                                            <div className="flex flex-row bg-gray-100 p-2 w-32 h-6 justify-center items-center text-black" style={{borderRadius: "30px"}}>Alterar Senha</div>
                                                        :
                                                            usuario.situacao === 1 ?
                                                                <div className="flex flex-row bg-green-300 p-2 w-32 h-6 justify-center items-center text-white" style={{borderRadius: "30px"}}>Ativo</div>
                                                            :
                                                                <div className="flex flex-row bg-red-300 p-2 w-32 h-6 justify-center items-center text-white" style={{borderRadius: "30px"}}>Inativo</div>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })

                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </LogadoLayout>

        <Dialog open={openModalUsuario}
                title={(usuario.id === ""?"Novo Usuário":usuario.nome)}
                onClose={() => setOpenModalUsuario(false)}
                secondaryButtonText="Resetar Senha"
                onSecondaryButtonClick={() => onResetar(usuario.id)}
                primaryButtonText="Salvar"
                onPrimaryButtonClick={onSalvar}
                width="500px">
            <div className="flex flex-col w-full">
                <label htmlFor="tbxNick">ID</label>
                <input value={usuario.nick} name="nick" id="tbxNick" type="text" onChange={onChangeValue} readOnly={(usuario.id != "") ? "readonly" : ""}></input>

                <label htmlFor="tbxNome" className="mt-3">Nome</label>
                <input value={usuario.nome} name="nome" id="tbxNome" type="text" onChange={onChangeValue}></input>

                <label htmlFor="tbxEmail" className="mt-3">Email</label>
                <input value={usuario.email} name="email" id="tbxEmail" type="text" onChange={onChangeValue}></input>

                <label htmlFor="selPerfil" className="mt-3">Perfil</label>
                <select value={usuario.idperfil} name="idperfil" id="selPerfil" onChange={onChangeValue}>
                    <option value="">[Selecione]</option>
                    {perfil && perfil.map(perfil => {
                        return (
                            <option value={perfil.id}>{perfil.descricao}</option>
                        )
                    })}
                </select>

                <label htmlFor="tbxCelular" className="mt-3">Celular</label>
                <input value={usuario.celular} name="celular" id="tbxCelular" type="text" onChange={onChangeValue}></input>

                <label htmlFor="tbxTelefone" className="mt-3">Telefone</label>
                <input value={usuario.telefone} name="telefone" id="tbxTelefone" type="text" onChange={onChangeValue}></input>                    

                <label htmlFor="selSituacao" className="mt-3">Situação</label>
                <select value={usuario.situacao} name="situacao" id="selSituacao" onChange={onChangeValue} disabled={(usuario.id !== "") ? "": "disabled"}>
                    <option value="-1">Alterar senha próximo login</option>
                    <option value="0">Inativo</option>
                    <option value="1" selected>Ativo</option>
                </select>                   

            </div>
        </Dialog>
    </>
    )
}