import React, {useEffect, useState} from "react";
import Dialog from "../../componentes/dialog";
import Cabecalho from "../../componentes/cabecalho";
import MenuPage from "../../componentes/menu";
import Skeleton from 'react-loading-skeleton';
import { pesquisaVendas, retornaAnexoPeloId } from "../../api/DataApi";
import moment from 'moment';
import { FiTrendingUp } from "react-icons/fi";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';
import LogadoLayout from "../../componentes/logado";


registerLocale('ptBR', ptBR);

export default function VendasPagina() {
    const [loading, setLoading] = useState(false);
    const [vendas, setVendas] = useState([]);
    const [checked, setChecked] = useState(true);
    const [data, setData] = useState(null);
    const [qtde, setQtde] = useState(0);
    const [total, setTotal] = useState(0);
    const [openInfoVenda, setOpenInfoVenda] = useState(false);
    const [infoVenda, setInfoVenda] = useState();

    const [filtro, setFiltro] = useState({
        datavenda: null,
        data: null,
        pesquisa: "",
        situacao: "-1"
    });

    const onPesquisar = () => {
        setLoading(true);
        if(!checked){
            setFiltro({...filtro, 
                datavenda: null});
        }else{
            setFiltro({...filtro, 
                datavenda: data});
        }
        pesquisaVendas(filtro.datavenda, filtro.pesquisa, filtro.situacao, callBackPesquisarVendas)
    }

    const callBackPesquisarVendas = (response) => {
        if (response && response.codigo === 0) {
            setQtde(response.quantidade);
            setVendas(response.vendas);

            let soma = 0;

            response.vendas.forEach(venda => {
                soma += (venda.produto) ? venda.produto.valor : 0;
            });

            setTotal(soma);
        }
        else {
            setQtde(0);
            setVendas([]);
        }

        setLoading(false);
    }

    const onAtualizaFiltro = (e) => {
        console.log("date", e);
        setFiltro({...filtro, [e.target.name]: e.target.value});
    }

    const onAtualizaData = (date) => {
        console.log("Teste data", date);
        let dataFiltro = moment(date).format("DD/MM/YYYY");
        setData(dataFiltro);

        console.log("DAte", dataFiltro)
        setFiltro({...filtro, datavenda: dataFiltro,
                              data: date});
    }

    useEffect(() => {
        console.log("FILTRO", filtro);
    }, [filtro])

    const showInfoVenda = (id) => {
        let venda = vendas.find(v => v.id === id);

        if (venda) {
            setOpenInfoVenda(true);
            setInfoVenda(venda);
        }
    }

    const retornaAnexo = (id) => {
        retornaAnexoPeloId(id, async (response) => {
            if (response.codigo === 0) {

                let arquivoBase64 = "data:" + response.anexo.tipo + ";base64," + response.anexo.base64;

                const pdfstr = await fetch(arquivoBase64);
                const blobFromFetch= await pdfstr.blob();
    
                var blob = new Blob([blobFromFetch], {type: response.anexo.tipo});
                
                const blobUrl = URL.createObjectURL(blob)

                window.open(blobUrl, '_blank');
            }
            console.log(response);
        } )
    }

    return (
        <>
            <LogadoLayout titulo="Consulta Propostas Integrador"
                          icon={<FiTrendingUp size={30}/>}>

                    <div className="sm:flex sm:flex-row items-center ml-4 mb-4 mt-4">
                        <div className="mr-1">Nº Proposta/CPF/Nome:</div>
                        <div className="mr-3"><input type="text" name="pesquisa" value={filtro.pesquisar} onChange={(e) => onAtualizaFiltro(e)}></input></div>

                        <div className="mr-1">
                        <input
                            type="checkbox"
                            onChange={(event) => setChecked(event.currentTarget.checked)}
                            checked={checked}
                        />
                        </div>       
                        {!checked ?
                            <div className="mr-1" style={{color:"gray"}}>Data Proposta:</div>
                        :
                            <div className="mr-1">Data Proposta:</div>
                        }
                        
                        <div className="mr-3">
                            <DatePicker 
                                locale="ptBR"
                                dateFormat="dd/MM/yyyy"
                                selected={filtro.data} 
                                onChange={(date) => onAtualizaData(date)}
                                disabled={!checked} />
                        
                        </div>
                        
                        
                        <div className="mr-1">Status:</div>
                        <div className="mr-3">
                            <select className="w-32" name="situacao" onChange={(e) => onAtualizaFiltro(e)}>
                                <option value="-1">Todas</option>
                                <option value="0">Aberta</option>
                                <option value="1">Gerada</option>
                                <option value="EMIT">Emitida</option>
                                <option value="TDA">Pendente</option>
                                <option value="NPAG">N/PAG</option>
                            </select>
                        </div>

                        <div>
                            <button className="w-28" onClick={onPesquisar}>Pesquisar</button>
                        </div>
                    </div>

                    <div className="overflow-auto p-5">
                        {loading ?
                            <Skeleton count={60} height={"30px"}/>
                        :
                            <>
                            {vendas && vendas.length > 0 ?
                                <div className="flex flex-col">
                                    <div className="flex flex-row mb-3">
                                        <div><b>Total de propostas:&nbsp;</b></div>
                                        <div><b>{qtde}</b></div>
                                    </div>
                                    <div className="flex flex-row mb-3">
                                        <div><b>Total vendido:&nbsp;</b></div>
                                        <div><b>{total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></div>
                                    </div>
                                    <table cellPadding={2} cellSpacing={2} border={0}>
                                        <thead>
                                            <th align="center">Data e Hora</th>
                                            <th align="center">Data autenticação</th>
                                            <th align="center">Nº Proposta</th>
                                            <th align="center">CPF</th>
                                            <th align="center">Nome Completo</th>
                                            <th align="center">Plano</th>
                                            <th align="center">Valor</th>
                                            <th align="center">Status</th>
                                        </thead>
                                        <tbody>
                                            {vendas && vendas.map((venda, index) => {
                                                return (
                                                    <tr key={index} className="bg-white hover:bg-gray-100 cursor-pointer" onClick={() => showInfoVenda(venda.id)}>
                                                        <td align="left">{moment(venda.dataAdicionado).format("DD/MM/YYYY HH:mm:ss")}</td>
                                                        <td align="left">{
                                                            (venda.dataAutenticacao !== null && (venda.dataAutenticacao !== "0001-01-01T00:00:00")) ?
                                                            moment(venda.dataAutenticacao).format("DD/MM/YYYY") :  ""
                                                        }</td>
                                                        <td align="left">{venda.numProposta}</td>
                                                        <td align="left">{venda.pfCpf}</td>
                                                        <td align="left">{venda.pfNome}</td>
                                                        <td align="left">{venda.produto?.codigo}</td>
                                                        <td align="right">{venda.produto?.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                                        <td align="center">
                                                            {venda.situacao === 0 ?
                                                                moment().diff(moment(venda.dataAdicionado), 'days') >= 7 ? 
                                                                <div className="flex flex-row justify-center items-center bg-red-300 w-20 h-8" style={{borderRadius: "30px"}}>N/PAG</div>
                                                                :
                                                                <div className="flex flex-row justify-center items-center bg-gray-200 w-20 h-8" style={{borderRadius: "30px"}}>Aberta</div>
                                                            :
                                                                venda.situacao === 4 ? 
                                                                    <div className="flex flex-row justify-center items-center text-white bg-caixa w-20 h-8" style={{borderRadius: "30px"}}>Emitida</div>
                                                                :
                                                                venda.situacao === 5  ? 
                                                                <div className="flex flex-row justify-center items-center bg-yellow-300 w-20 h-8" style={{borderRadius: "30px"}}>Pendente</div>
                                                            : 
                                                                venda.situacao === 6  ? 
                                                                <div className="flex flex-row justify-center items-center bg-red-300 w-20 h-8" style={{borderRadius: "30px"}}>N/PAG</div>
                                                            :
                                                                        <div className="flex flex-row justify-center items-center bg-green-200 w-20 h-8" style={{borderRadius: "30px"}}>Gerada</div>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            :
                                <div><b>Busque propostas usando o filtro</b></div>
                            }
                            </>
                        }
                    </div>
            </LogadoLayout>

            <Dialog open={openInfoVenda}
                    title={
                        <>
                        <div className="flex flex-row">
                            <div>Detalhes da Proposta&nbsp;-&nbsp;{infoVenda?.numProposta}</div>
                        </div>
                        </>
                    }
                    onClose={() => setOpenInfoVenda(false)}
                    primaryButtonText=""
                    width="850px">
                <div>
                    {infoVenda &&
                    <>
                        <div className="flex flex-col w-full">
                            <table cellPadding={2} cellSpacing={2} border={0} className="m-3">
                                <tr>
                                    <td>Status:</td>
                                    <td>
                                    {infoVenda.situacao === 0 ?
                                        moment().diff(moment(infoVenda.dataAdicionado), 'days') >= 7 ? 
                                        <div className="flex flex-row justify-center items-center bg-red-300 w-20 h-8" style={{borderRadius: "30px"}}>N/PAG</div>
                                        :
                                        <div className="flex flex-row justify-center items-center bg-gray-200 w-20 h-8" style={{borderRadius: "30px"}}>Aberta</div>
                                    :
                                        infoVenda.situacao === 4 ? 
                                            <div className="flex flex-row justify-center items-center bg-caixa w-20 h-8" style={{borderRadius: "30px"}}>Emitida</div>
                                        :
                                        infoVenda.situacao === 5  ? 
                                                <div className="flex flex-row justify-center items-center bg-red-300 w-20 h-8" style={{borderRadius: "30px"}}>Pendente</div>
                                            :
                                            infoVenda.situacao === 6  ? 
                                                <div className="flex flex-row justify-center items-center bg-red-300 w-20 h-8" style={{borderRadius: "30px"}}>N/PAG</div>
                                            :
                                                <div className="flex flex-row justify-center items-center bg-green-200 w-20 h-8" style={{borderRadius: "30px"}}>Gerada</div>
                                    }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Nome completo:</td>
                                    <td>{infoVenda.pfNome}</td>
                                </tr>                             
                                <tr>
                                    <td>CPF:</td>
                                    <td>{infoVenda.pfCpf}</td>
                                </tr>                                                                                 
                                <tr>
                                    <td>Endereço:</td>
                                    <td>{infoVenda.logradouro}</td>
                                </tr>     
                                <tr>
                                    <td>Bairro:</td>
                                    <td>{infoVenda.bairro}</td>
                                </tr>  
                                <tr>
                                    <td>Cidade:</td>
                                    <td>{infoVenda.cidade}</td>
                                </tr>  
                                <tr>
                                    <td>UF:</td>
                                    <td>{infoVenda.uf}</td>
                                </tr> 
                                <tr>
                                    <td>Cep:</td>
                                    <td>{infoVenda.cep}</td>
                                </tr>  
                                <tr>
                                    <td>Celular:</td>
                                    <td>({infoVenda.pfContatoCelularDDD})&nbsp;{infoVenda.pfContatoCelularNumero}</td>
                                </tr>     
                                <tr>
                                    <td>Residêncial:</td>
                                    <td>({infoVenda.pfContatoResidencialDDD})&nbsp;{infoVenda.pfContatoResidencialNumero}</td>
                                </tr>     
                                <tr>
                                    <td>Contato Email:</td>
                                    <td>{infoVenda.pfContatoEmail}</td>
                                </tr> 
                                <tr>
                                    <td>Plano contratado:</td>
                                    <td>{infoVenda.produto?.codigo}</td>
                                </tr>
                                <tr>
                                    <td>Data e hora de contratação:</td>
                                    <td>{moment(infoVenda.dataAdicionado).format("DD/MM/YYYY HH:mm:ss")}</td>
                                </tr>

                                <tr>
                                    <td>Data e hora de autenticação:</td>

                                    <td>{
                                        (infoVenda.dataAutenticacao !== null && (infoVenda.dataAutenticacao !== "0001-01-01T00:00:00")) ?
                                        moment(infoVenda.dataAutenticacao).format("DD/MM/YYYY") :  ""}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Forma Pagamento:</td>
                                    <td>{infoVenda.vendaPagamento}</td>
                                </tr>  
                                <tr>
                                    <td>Dados bancários:</td>
                                    <td>{infoVenda.vendaContaAgencia}-{infoVenda.vendaConta} {infoVenda.vendaContaDigito}</td>
                                </tr>  
                                <tr>
                                    <td>Boleto:</td>
                                    <td>{infoVenda.vendaUrl}</td>
                                </tr>                             
                                <tr>
                                    <td>Venda Código de Barras:</td>
                                    <td>{infoVenda.vendaCodigoBarras}</td>
                                </tr>
                                <tr>
                                    <td>Venda linha digitável:</td>
                                    <td>{infoVenda.vendaLinhaDigitavel}</td>
                                </tr>
                                <tr>
                                    <td>Venda Recorrência:</td>
                                    <td>{infoVenda.vendaRecorrencia}</td>
                                </tr>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                            </table>
                            
                            <div className="flex flex-row self-end">
                                {infoVenda?.idAnexoBoleto &&
                                    <div className="self-end">
                                        <button style={{width: "130px"}} onClick={() => retornaAnexo(infoVenda.idAnexoBoleto)}>Ver Boleto</button>
                                    </div>
                                }
                                &nbsp;&nbsp;
                                {infoVenda?.idAnexoProposta &&
                                    <div className="self-end">
                                        <button style={{width: "130px"}} onClick={() => retornaAnexo(infoVenda.idAnexoProposta)}>Ver Proposta</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                    }
               </div>
            </Dialog>            
        </>
    )
}