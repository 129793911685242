

import React, { useState, Component } from "react";
import Chart from "react-apexcharts";

export default function BarDistributed({regioes,totais, periodo, titulo}) {
const [vendas, setVendas] = useState([]);
const [loading, setLoading] = useState(false);

class SpilineChart extends React.Component {
    constructor(props) {
      super(props);

      var colors = [
        '#008FFB',
        '#529F51',
        '#FF7F27',
        '#6000BF',
        '#ED1C24',
        '#8080FF',
        '#FF0080',
        '#D10CE8',
        '#408080',
      ]

      this.state = {
        
        series: [{
          data: [...totais]
        }],
        options: {
            chart: {
              type: 'bar',
              height: 380,
              background: '#ffffff'
            },
            plotOptions: {
              bar: {
                barHeight: '100%',
                distributed: true,
                horizontal: true,
                startingShape: "flat",
                endingShape: "rounded",
                dataLabels: {
                  position: 'bottom'
                },
              }
            },
            colors: colors,
            dataLabels: {
              enabled: true,
              textAnchor: 'start',
              style: {
                colors: ['#fff'],
                fontSize: '30px',
              },
              formatter: function (val, opt) {
                return val
              },
              offsetX: 0,
              dropShadow: {
                enabled: false
              }
            },
            stroke: {
              width: 3,
              colors: ['#fff']
            },
            xaxis: {
              categories: [...regioes],
              
            },
            yaxis: {
              labels: {
                show: false
              }
            },
            title: {
                text: titulo,
                align: 'center',
                floating: true
            },
            subtitle: {
                text: periodo,
                align: 'center',
            },
            tooltip: {
              theme: 'dark',
              x: {
                show: false
              },
              y: {
                title: {
                  formatter: function () {
                    return ''
                  }
                }
              }
            },
            legend: {
                show: true,
                showForSingleSeries: false,
                showForNullSeries: true,
                showForZeroSeries: true,
                position: 'bottom',
                horizontalAlign: 'center', 
                floating: false,
                fontSize: '20px',
                fontFamily: 'Helvetica, Arial',
                fontWeight: 400,
                formatter: undefined,
                inverseOrder: false,
                width: undefined,
                height: undefined,
                tooltipHoverFormatter: undefined,
                customLegendItems: [],
                offsetX: 0,
                offsetY: 0,
                labels: {
                    colors: undefined,
                    useSeriesColors: false
                },
                markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    strokeColor: '#fff',
                    fillColors: undefined,
                    radius: 12,
                    customHTML: undefined,
                    onClick: undefined,
                    offsetX: 0,
                    offsetY: 0
                },
                itemMargin: {
                    horizontal: 5,
                    vertical: 0
                },
                onItemClick: {
                    toggleDataSeries: true
                },
                onItemHover: {
                    highlightDataSeries: true
                },
            }
          },
      };
    }
      render() {
        return (
          <Chart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                height="80%"
                width="100%"
              />
        );
      }
    }
   
  return (
    <div className="flex flex-col w-full">
        <div className="flex flex-col">
          <div className="flex flex-row h-full">
            <div className="w-full" style={{ height: "calc(100vh - 50px)", overflow: "hidden" }}>
              <SpilineChart/>
            </div>
          </div>
        </div>
      </div>
  )
}


