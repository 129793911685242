import React, { useContext, useEffect, useState } from "react";
import { Carousel } from 'react-responsive-carousel';
export default function CarouselModelo() {
    return (
        <div>
            <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false} dynamicHeight={true}>
                <div>
                    <img className="img-carousel" src="../../imagens/1655314491722.jpg" />
                </div>
                <div>
                    <img className="img-carousel" src="../../imagens/1655314491734.jpg" />
                </div>
                <div>
                    <img className="img-carousel" src="../../imagens/1655314491740.jpg" />
                </div>
                <div>
                    <img className="img-carousel" src="../../imagens/1655315014045.jpeg" />
                </div>
                <div>
                    <img className="img-carousel" src="../../imagens/1655315014046.jpg" />
                </div>
                <div>
                    <img className="img-carousel" src="../../imagens/1655314491848.jpg" />
                </div>
                <div>
                    <img className="img-carousel" src="../../imagens/1655314491847.jpg" />
                </div>
                <div>
                    <img className="img-carousel" src="../../imagens/1655315013885.jpg" />
                </div>
                <div>
                    <img className="img-carousel" src="../../imagens/1655315013901.jpg" />
                </div>
                <div>
                    <img className="img-carousel" src="../../imagens/1655315014044.jpg" />
                </div>
            </Carousel>
        </div>
    )
}