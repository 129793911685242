import React, { useEffect, useState } from "react";
import { FiDollarSign, FiLayers } from "react-icons/fi";
import Cabecalho from "../../componentes/cabecalho";
import MenuPage from "../../componentes/menu";
import Skeleton from 'react-loading-skeleton';
import { IMaskInput, IMask } from "react-imask";
import { pesquisaVendasUnidadesAgrupadas } from "../../api/DataApi";
import moment from 'moment';
import LogadoLayout from "../../componentes/logado";
import ptBR from 'date-fns/locale/pt-BR';
import DatePicker, { registerLocale } from "react-datepicker";

registerLocale('ptBR', ptBR);

export default function VendasUnidadesAgrupadasPagina() {
    const [loading, setLoading] = useState(false);
    const [vendas, setVendas] = useState([]);
    const [qtde, setQtde] = useState(0);
    const [validacao, setValidacao] = useState(null);
    const [errorData, setErorData] = useState(null);
    const [errorCampoBusca, setErrorCampoBusca] = useState(null);
    const [pM_Total_Geral, setpM_Total_Geral] = useState(0);
    const [pM_qtd_Total_Geral, setpM_qtd_Total_Geral] = useState(0);
    const [puA_Total_Geral, setpuA_Total_Geral] = useState(0);
    const [puP_Total_Geral, setpuP_Total_Geral] = useState(0);
    const [puA_qtd_Total_Geral, setpuA_qtd_Total_Geral] = useState(0);
    const [puP_qtd_Total_Geral, setpuP_qtd_Total_Geral] = useState(0);
    const [puS_Total_Geral, setpuS_Total_Geral] = useState(0);
    const [puS_qtd_Total_Geral, setpuS_qtd_Total_Geral] = useState(0);
    const [qntTotal_Total_Geral, setqntTotal_Total_Geral] = useState(0);
    const [valorTotal_Total_Geral, setvalorTotal_Total_Geral] = useState(0);

    const [filtro, setFiltro] = useState({
        dataInicio: new Date(),
        dataFin: new Date(),
        agrupamento: "1",
        tipoConsulta: "",
        txtBusqueda: "",
        regiao: ""
    });

    const onAtualizaFiltro = (e) => {
        setFiltro({ ...filtro, [e.target.name]: e.target.value });
    }

    const onPesquisar = () => {

        let errorData = false;
        let errorBusqueda = false;
        let dataInicio = moment(filtro.dataInicio, "DD/MM/YYYY").format("YYYY-MM-DD");
        let dataFin = moment(filtro.dataFin, "DD/MM/YYYY").format("YYYY-MM-DD");

        let regiao = filtro.regiao === "" ? undefined : filtro.regiao;
        let agrupamento = filtro.agrupamento === "" ? undefined : filtro.agrupamento;
        let tipoConsulta = filtro.tipoConsulta === "" ? undefined : filtro.tipoConsulta;
        let txtBusqueda = filtro.txtBusqueda === "" ? undefined : filtro.txtBusqueda;

        if (txtBusqueda === undefined && tipoConsulta !== undefined) {
            setValidacao("Deve preencher o campo de busca se o Tipo da consulta esta selecionada.");
            setErrorCampoBusca("bg-red-300")
            errorBusqueda = true;
        } else {
            errorBusqueda = false;
        }


        let dias = moment(dataFin).diff(moment(dataInicio), 'days');

        if (dias <= 60) {
            errorData = false;
        } else {
            setValidacao("Os intervalos das datas devem ser menor a 60 dias.")
            setErorData("bg-red-300")
            errorData = true;
        }

        if (!errorData && !errorBusqueda) {
            setLoading(true);
            setValidacao(null);
            setErorData("");
            setErrorCampoBusca("");
            pesquisaVendasUnidadesAgrupadas(dataInicio, dataFin, agrupamento, tipoConsulta, txtBusqueda, regiao, callBackPesquisarVendas)
        }

    }

    const callBackPesquisarVendas = (response) => {
        console.log("Pesquisar", response);

        if (response) {
            setQtde(response.quantidade);
            setVendas(response.vendas);
            setpM_Total_Geral(response.pM_Total_Geral);
            setpM_qtd_Total_Geral(response.pM_qtd_Total_Geral);
            setpuA_Total_Geral(response.puA_Total_Geral);
            setpuP_Total_Geral(response.puP_Total_Geral);
            setpuA_qtd_Total_Geral(response.puA_qtd_Total_Geral);
            setpuP_qtd_Total_Geral(response.puP_qtd_Total_Geral);
            setpuS_Total_Geral(response.puS_Total_Geral);
            setpuS_qtd_Total_Geral(response.puS_qtd_Total_Geral);
            setqntTotal_Total_Geral(response.qntTotal_Total_Geral);
            setvalorTotal_Total_Geral(response.valorTotal_Total_Geral);
        }

        setLoading(false);
    }

    const onAtualizaData = (date, tipo) => {
        setFiltro({ ...filtro, [tipo]: date });
    }

    const moneyPipe = IMask.createPipe({
        mask: 'R$ num',
        blocks: {
            num: {
                mask: Number,
                signed: false,
                thousandsSeparator: '.',
                scale: 2,
                normalizeZeros: true,
                padFractionalZeros: true,
            }
        }

    });
    return (
        <>
            <LogadoLayout titulo="Consulta sintética - Data autenticação" valorOverflow="hidden"
                icon={<FiLayers size={30} />}>
                <div className="grid grid-flow-row align-middle sm:flex sm:flex-row items-center ml-4 mb-4 mt-4">
                    <div className="sm:flex sm:flex-row items-center mb-3">
                        <div class="sm:flex sm:flex-row sm:items-center mb-3">
                            <div className="mr-3">Data Início: </div>
                            <div className="mr-3">
                                <DatePicker
                                    locale="ptBR"
                                    dateFormat="dd/MM/yyyy"
                                    selected={filtro.dataInicio}
                                    onChange={(date) => onAtualizaData(date, 'dataInicio')}
                                    className={errorData} />
                            </div>
                        </div>
                        <div class="sm:flex sm:flex-row sm:items-center mb-3 ">
                            <div className="mr-3">Data Fim: </div>
                            <div className="mr-3">
                                <DatePicker
                                    locale="ptBR"
                                    dateFormat="dd/MM/yyyy"
                                    selected={filtro.dataFin}
                                    onChange={(date) => onAtualizaData(date, 'dataFin')}
                                    className={errorData} />
                            </div>
                        </div>
                        <div>
                            {validacao && <span className="text-red-500">{validacao}</span>}
                        </div>
                    </div>
                </div>

                <div className="grid grid-flow-row align-middle sm:flex sm:flex-row items-center ml-4 mb-4 mt-4">
                    <div class="flex flex-row items-center mb-3">
                        <div class="sm:flex sm:flex-row sm:items-center">
                            <div className="mr-3">Agrupamento: </div>
                            <div className="mr-3">
                                <select className="w-32" name="agrupamento" onChange={(e) => { onAtualizaFiltro(e) }}>
                                    <option value="1">SEV</option>
                                    <option value="2">AG</option>
                                    <option value="3">SR</option>
                                </select>
                            </div>
                        </div>
                        <div class="sm:flex sm:flex-row sm:items-center">
                            <div className="mr-3">Região: </div>
                            <div className="mr-3">
                                <select className="w-32" name="regiao" onChange={(e) => { onAtualizaFiltro(e) }}>
                                <option value="">Todos</option>
                                        <option value="1">Centro-Oeste</option>
                                        <option value="2">Nordeste</option>
                                        <option value="3">Norte</option>
                                        <option value="4">São Paulo - Capital e Litoral</option>
                                        <option value="5">São Paulo - Interior</option>
                                        <option value="6">Sudeste</option>
                                        <option value="7">Sul</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row items-center mb-3">
                        <div class="sm:flex sm:flex-row sm:items-center">
                            <div className="mr-3">Tipo da consulta: </div>
                            <div className="mr-3">
                                <select className="w-32" name="tipoConsulta" onChange={(e) => { onAtualizaFiltro(e) }}>
                                    <option value="">NENHUMA</option>
                                    <option value="1">SEV</option>
                                    <option value="2">AG</option>
                                    <option value="3">SR</option>
                                </select>
                            </div>
                        </div>
                        <div class="sm:flex sm:flex-row sm:items-center">
                            <div className="mr-3">Campo de busca: </div>
                            <div className="mr-3">
                                <input type="text" className={errorCampoBusca + " w-28"} name="txtBusqueda"
                                    disabled={filtro.tipoConsulta === ""}
                                    value={filtro.txtBusqueda}
                                    onChange={(e) => onAtualizaFiltro(e)} ></input>
                            </div>
                        </div>
                    </div>
                    <div className="w-16"></div>
                    <div>
                        <button className="w-28" onClick={onPesquisar}>Pesquisar</button>
                    </div>

                </div>

                <div className="flex flex-row items-center pl-5 ">
                    <div >Quantidade:</div>
                    <div>{qtde}</div>
                </div>
                <div className="overflow-auto p-5 scrollbar-none">
                    {loading ?
                        <Skeleton count={60} height={"30px"} />
                        :
                        <div>
                            <div className="flex flex-col">
                                <div className="tableFixHead">
                                    <table cellPadding={2} cellSpacing={2} border={0}>
                                        <thead>
                                            <tr>
                                                <th align="left">CGC</th>
                                                <th align="left">Nome</th>
                                                <th align="left">UF</th>
                                                <th align="left">PM(R$)</th>
                                                <th align="right">PM</th>
                                                <th align="left">PUS(R$)</th>
                                                <th align="right">PUS</th>
                                                <th align="left">PUA(R$)</th>
                                                <th align="right">PUA</th>
                                                <th align="left">PUP(R$)</th>
                                                <th align="right">PUP</th>
                                                <th align="left">TOTAL(R$)</th>
                                                <th align="right">TOTAL</th>
                                                {/* <th align="left">Tipo</th> */}
                                                {/* <th align="left">Venda</th> */}
                                                {/* <th align="left">Código UL</th> */}
                                                {/* <th align="left">UF</th> */}
                                                {/* <th align="left">Região</th> */}
                                                {/* <th align="left">Canal</th> */}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {vendas && vendas.map((venda, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td align="left">{venda.cgc}</td>
                                                        <td align="left">{venda.nomeCgc}</td>
                                                        <td align="left">{venda.uf}</td>
                                                        <td align="right">{moneyPipe(venda.pm.toString())}</td>
                                                        <td align="right">{venda.pM_qtd}</td>
                                                        <td align="right">{moneyPipe(venda.pus.toString())}</td>
                                                        <td align="right">{venda.puS_qtd}</td>
                                                        <td align="right">{moneyPipe(venda.pua.toString())}</td>
                                                        <td align="right">{venda.puA_qtd}</td>
                                                        <td align="right">{moneyPipe(venda.pup.toString())}</td>
                                                        <td align="right">{venda.puP_qtd}</td>
                                                        <td align="right">{moneyPipe(venda.valorTotal.toString())}</td>
                                                        <td align="right">{venda.qntTotal}</td>
                                                        {/* <td align="left">{venda.coD_UL}</td> */}
                                                        {/* <td align="left">{venda.uf}</td> */}
                                                        {/* <td align="left">{venda.regiao}</td> */}
                                                        {/* <td align="left">{venda.canal}</td> */}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>

                                    
                                        <tfoot>
                                            <tr className="fundo-cinza">
                                                <td colSpan="3" align="left" ><b>TOTAIS:</b></td>
                                                <td align="left">{moneyPipe(pM_Total_Geral.toString())}</td>
                                                <td align="right">{pM_qtd_Total_Geral}</td>
                                                <td align="left">{moneyPipe(puS_Total_Geral.toString())}</td>
                                                <td align="right">{puS_qtd_Total_Geral}</td>
                                                <td align="left">{moneyPipe(puA_Total_Geral.toString())}</td>
                                                <td align="right">{puA_qtd_Total_Geral}</td>
                                                <td align="left">{moneyPipe(puP_Total_Geral.toString())}</td>
                                                <td align="right">{puP_qtd_Total_Geral}</td>
                                                <td align="left">{moneyPipe(valorTotal_Total_Geral.toString())}</td>
                                                <td align="right">{qntTotal_Total_Geral}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </LogadoLayout>
        </>
    );

}