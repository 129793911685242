import React, { useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton';
import { efetividade, telasAtivas } from "../../../api/DataApi";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';
import MenuPainel from "../componentes/menuPainel"
import RadialBarMultiplos from '../../../componentes/charts/radialBarMultiplos';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';


registerLocale('ptBR', ptBR);

export default function VendasDiaRegiao() {
  const [telas, setTelas] = useState([]);
  const [vendas, setVendas] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    telasAtivas(callBackTelasAtivas)
  }, []);

  useEffect(() => {
    setTimeout(() => {
      nextTela()
    }, 60000);
  }, []);

  const callBackTelasAtivas = (response) => {
    setLoading(false);
    if (response) {
      setTelas(response.telasAtivas);

    }
  }

  function nextTela() {
    {
      let num = telas.length;
      let id = parseInt(location.state.id);

      let indice;
      setTelas((state) => {

        state.map((item, i) => {
          if (id >= num) {
            i = 0
            id = 0
          }
          if (i == id) {
            const { url } = item;
            indice = i++;
            return (
              navigate('../vendasDiaRegiao', { state: { id: 1, name: 'sabaoon' } })
            )
          }
        })
      });
    }
  }

  useEffect(() => {
    efetividade(callBackEfetividade);
  }, []);

  const callBackEfetividade = (response) => {
    setLoading(false);
    if (response) {
      setVendas(response.efetividade);
    }
  }

  const conversao = (vendas.map(venda => {
    return (
      venda.convertido
    )
  })
  )
  const regioes = (vendas.map(venda => {
    return (
      venda.regiao
    )
  })
  )

  var mesNome = Array("Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosoto", "Setembro", "Outubro", "Novembro", "Dezembro");
  const date = new Date();
  const mes = date.getMonth();

  return (
    <>
      <div className="flex flex-col" style={{ height: "calc(100vh)", overflow: "hidden" }}>
        {telas.map((item, i) => {
          const { descricao, nome } = item;
          if (nome == "Efetividade") {
            return (
              <MenuPainel titulo={descricao} texto={mesNome[mes]} font="h3-painel-efetividade" />
            )
          }
        })
        }

        <div className="w-full" style={{ height: "calc(100vh)", }}>
          <div className="flex flex-col w-full">
            <div className="overflow-auto p-5">
              {loading ?
                <Skeleton count={60} height={"30px"} />
                :
                <RadialBarMultiplos regioes={regioes} porcentagem={conversao}
                />
              }
            </div>
          </div>

        </div>

      </div>
    </>
  )
}