import React, {useEffect, useState} from "react";
import { listaTela, retornaTelaPeloId, salvarTela } from "../../api/DataApi";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { FiPlus } from "react-icons/fi";
import Dialog from "../../componentes/dialog";
import { AiOutlineUser, AiOutlineUsergroupAdd } from "react-icons/ai";
import LogadoLayout from "../../componentes/logado";

export default function TelasPagina() {
    const [telas, setTelas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openModalTela, setOpenModalTela] = useState(false);
    const [perfil, setPerfil] = useState();

    const [tela, setTela] = useState({
        id: "",
        nome: "",
        url: "",
        descricao: "",
        tempo: "",
        situacao: 0
    });

    useEffect(() => {
        listaTela(callBackListaTelas);
    }, []);

    const callBackListaTelas = (response) => {
        setLoading(false);

        if (response) {
            console.log(response);
            setTelas(response.listaTela);
        }
    }


    const onOpenModalTela = (id) => {

        if (id) {
            retornaTelaPeloId(id, callBackRetornaTelaPeloId);
        }
        else {
            setTela( {
                id: "",
                nome: "",
                url: "",
                descricao: "",
                tempo: "",
                situacao: 0
            } );

            setOpenModalTela(true);
        }

    }

    const callBackRetornaTelaPeloId = (response) => {
        console.log("callBackRetornaTelaPeloId", response);
        
        if (response) {
            setTela({
                id: response.tela.id,
                nome: response.tela.nome,
                url: response.tela.url,
                descricao: response.tela.descricao,
                tempo: response.tela.tempo,
                situacao: response.tela.situacao    
            });

            setOpenModalTela(true);            
        }
    }

    useEffect(() => {
        console.log("tela", tela);
    }, [tela])


    const onSalvar = () => {
        salvarTela(tela, callBackSalvarTela);
    }

    const callBackSalvarTela = (response, loading = true) => {
        if (response && response?.codigo === 0) {
            setOpenModalTela(false);
            setLoading(loading);
            listaTela(callBackListaTelas);
        }
        console.log("Salvar Tela:", response);
    }

    const onChangeValue = (e) => {
        console.log("onChangeValue", e.target.name, e.target.value);

        setTela({...tela, [e.target.name]: e.target.value});
    }


    return (
        <>
        <LogadoLayout
            titulo="Lista de Telas do Painel TV"
            icon={<AiOutlineUser size={30}/>}
            opcao={
                <button className="flex flex-row p-4 items-center justify-between" onClick={() => onOpenModalTela(null)}>
                    <FiPlus size={15}/>
                    Nova Tela
                </button> }>
            <div className="flex flex-col w-full">

                <div className="overflow-auto p-5">
                    {loading ?
                        <Skeleton count={60} height={"30px"}/>
                    :
                        <div className="flex flex-col">
                            <table cellPadding={2} cellSpacing={2} border={0}>
                                <thead>
                                    <th align="left">Nome</th>
                                    <th align="left">Url</th>
                                    <th align="left">Descrição</th>
                                    <th align="left">Tempo</th>
                                    <th align="center">Situação</th>
                                </thead>
                                <tbody>
                                    {telas && telas.map(tela => {
                                        return (
                                            <tr className="cursor-pointer bg-white hover:bg-gray-100" onClick={() => onOpenModalTela(tela.id)}>
                                                
                                                <td align="left">{tela.nome}</td>
                                                <td align="left">{tela.url}</td>
                                                <td align="left">{tela.descricao}</td>
                                                <td align="left">{tela.tempo}</td>
                                                <td align="center">
                                                    {
                                                         tela.situacao === 1 ?
                                                                <div className="flex flex-row bg-green-300 p-2 w-32 h-6 justify-center items-center text-white" style={{borderRadius: "30px"}}>Ativo</div>
                                                            :
                                                                <div className="flex flex-row bg-red-300 p-2 w-32 h-6 justify-center items-center text-white" style={{borderRadius: "30px"}}>Inativo</div>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })

                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </LogadoLayout>

        <Dialog open={openModalTela}
                title={(tela.id === ""?"Nova Tela":tela.nome)}
                onClose={() => setOpenModalTela(false)}
                primaryButtonText="Salvar"
                onPrimaryButtonClick={onSalvar}
                width="500px">
            <div className="flex flex-col w-full">
               
                <label htmlFor="tbxNome" className="mt-3">Nome</label>
                <input value={tela.nome} name="nome" id="tbxNome" type="text" onChange={onChangeValue}></input>

                <label htmlFor="tbxUrl" className="mt-3">URL</label>
                <input value={tela.url} name="url" id="tbxUrl" type="text" onChange={onChangeValue}></input>

                <label htmlFor="tbxDescricao" className="mt-3">Descrição</label>
                <input value={tela.descricao} name="descricao" id="tbxDescricao" type="text" onChange={onChangeValue}></input>

                <label htmlFor="tbxTempo" className="mt-3">Tempo</label>
                <input value={tela.tempo} name="tempo" id="tbxTempo" type="text" onChange={onChangeValue}></input>

                <label htmlFor="selSituacao" className="mt-3">Situação</label>
                <select value={tela.situacao} name="situacao" id="selSituacao" onChange={onChangeValue} disabled={(tela.id !== "") ? "": "disabled"}>
                    <option value="0">Inativo</option>
                    <option value="1" selected>Ativo</option>
                </select>                   

            </div>
        </Dialog>
    </>
    )
}