import React, { useEffect, useState } from "react";
import Dialog from "../../componentes/dialog";
import { FiArrowDown, FiLayers, FiArrowUp } from "react-icons/fi";
import Cabecalho from "../../componentes/cabecalho";
import MenuPage from "../../componentes/menu";
import Skeleton from 'react-loading-skeleton';
import { IMaskInput, IMask } from "react-imask";
import { pesquisaVendasTaspjunc, pesquisaVendasUnidades } from "../../api/DataApi";
import moment from 'moment';
import LogadoLayout from "../../componentes/logado";
import ptBR from 'date-fns/locale/pt-BR';
import DatePicker, { registerLocale } from "react-datepicker";

registerLocale('ptBR', ptBR);

export default function RelatorioTaspjunc() {
    const [loading, setLoading] = useState(false);
    const [vendas, setVendas] = useState([]);
    const [qtde, setQtde] = useState(0);
    const [total, setTotal] = useState(0);
    const [openInfoVenda, setOpenInfoVenda] = useState(false);
    const [infoVenda, setInfoVenda] = useState();
    const [validacao, setValidacao] = useState(null);
    const [sortConfig, setSortConfig] = useState(null);
    const [errorData, setErorData] = useState(null);

    const [filtro, setFiltro] = useState({
        dataInicio: new Date(),
        dataFin: new Date(),
        canal_macro: "",
        canal: "",
        tipo_pagamento: "",
        produto: ""
    });

    const onAtualizaFiltro = (e) => {
        setFiltro({ ...filtro, [e.target.name]: e.target.value });
    }

    const onPesquisar = () => {

        let dataInicio = moment(filtro.dataInicio, "DD/MM/YYYY").format("YYYY-MM-DD");
        let dataFin = moment(filtro.dataFin, "DD/MM/YYYY").format("YYYY-MM-DD");

        let canal_macro = filtro.canal_macro === "" ? undefined : filtro.canal_macro;
        let canal = filtro.canal === "" ? undefined : filtro.canal;
        let tipo_pagamento = filtro.tipo_pagamento === "" ? undefined : filtro.tipo_pagamento;
        let produto = filtro.produto === "" ? undefined : filtro.produto;

        let dias = moment(dataFin).diff(moment(dataInicio), 'days');

        if (dias <= 60) {
            setLoading(true);
            setValidacao(null);
            pesquisaVendasTaspjunc(dataInicio, dataFin, canal_macro, canal, tipo_pagamento, produto, callBackPesquisarVendasTaspjunc)

        } else {
            setValidacao("Os intervalos das datas devem ser menor a 60 dias.")
            setErorData("bg-red-300")
        }

    }

    const showInfoVenda = (venda) => {

        if (venda) {
            setOpenInfoVenda(true);
            setInfoVenda(venda);
        }
    }

    const callBackPesquisarVendasTaspjunc = (response) => {
        console.log("Pesquisar", response);

        if (response) {
            setQtde(response.quantidade);
            setVendas(response);
            setTotal(response.reduce((s, a) => s + a.valorPago, 0))
        }

        setLoading(false);
    }

    const moneyPipe = IMask.createPipe({
        mask: 'R$ num',
        blocks: {
            num: {
                mask: Number,
                signed: false,
                thousandsSeparator: '.',
                scale: 2,
                normalizeZeros: true,
                padFractionalZeros: true,
            }
        }

    });

    const onAtualizaData = (date, tipo) => {
        setFiltro({ ...filtro, [tipo]: date });
    }

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        setVendas(sortedItems);
    };

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? (sortConfig.direction === "ascending" ? <FiArrowDown className="d-inline" /> : <FiArrowUp className="d-inline" />) : undefined;
    };

    const sortedItems = React.useMemo(() => {
        let sortableItems = [...vendas];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [vendas, sortConfig]);

    return (

        <LogadoLayout titulo="Vendas por data de autenticação" valorOverflow="hidden"
            icon={<FiLayers size={30} />}>
            <div className="flex flex-col w-full">
                <div className="w-full grid sm:flex sm:flex-row sm:items-center ml-4 mb-4 mt-4 mr-4">
                    <div className="mr-3">Data Início: </div>
                    <div className="mr-3">
                        <DatePicker
                            locale="ptBR"
                            dateFormat="dd/MM/yyyy"
                            selected={filtro.dataInicio}
                            onChange={(date) => onAtualizaData(date, 'dataInicio')}
                            className={errorData} />
                    </div>

                    <div className="mr-3">Data Fim: </div>
                    <div className="mr-3">
                        <DatePicker
                            locale="ptBR"
                            dateFormat="dd/MM/yyyy"
                            selected={filtro.dataFin}
                            onChange={(date) => onAtualizaData(date, 'dataFin')}
                            className={errorData} />

                    </div>
                    <div>
                        {validacao && <span className="text-red-500">{validacao}</span>}
                    </div>
                </div>

                <div className="grid grid-flow-row align-middle sm:flex sm:flex-row sm:items-center ml-4 mb-4 mt-4 mr-4">
                    <div className="flex flex-row items-center mb-3">
                        <div class="sm:flex sm:flex-row sm:items-center">
                            <div className="mr-3">Canal macro: </div>
                            <div className="mr-6">
                                <select className="w-32" name="canal_macro" onChange={(e) => { onAtualizaFiltro(e) }}>
                                    <option value="">Todos</option>
                                    <option value="SIGPF WEB">SIGPF WEB</option>
                                    <option value="PAC">PAC</option>
                                </select>
                            </div>
                        </div>
                        <div class="sm:flex sm:flex-row sm:items-center">
                            <div className="mr-3">Canal: </div>
                            <div className="mr-6">
                                <select className="w-32" name="canal" onChange={(e) => { onAtualizaFiltro(e) }}>
                                    <option value="">Todos</option>
                                    <option value="AG">AG</option>
                                    <option value="SISPL">SISPL</option>
                                    <option value="CCA">CCA</option>
                                    <option value="ATM">ATM</option>
                                    <option value="UL">UL</option>
                                    <option value="OUTROS">OUTROS</option>
                                </select>
                            </div>
                        </div>
                        <div class="sm:flex sm:flex-row sm:items-center">
                            <div className="mr-3">Tipo de pagamento: </div>
                            <div className="mr-6">
                                <select className="w-32" name="tipo_pagamento" onChange={(e) => { onAtualizaFiltro(e) }}>
                                    <option value="">Todos</option>
                                    <option value="BOLETO">BOLETO</option>
                                    <option value="DEBITO CC">DEBITO CC</option>
                                    <option value="PAGAMENTO PEC">PAGAMENTO PEC</option>
                                </select>
                            </div>
                        </div>
                        <div class="sm:flex sm:flex-row sm:items-center">
                            <div className="mr-3">Produto: </div>
                            <div className="mr-6">
                                <select className="w-32" name="produto" onChange={(e) => { onAtualizaFiltro(e) }}>
                                    <option value="">Todos</option>
                                    <option value="PM - Mensal">PM - Mensal</option>
                                    <option value="PUS - Semestral">PUS - Semestral</option>
                                    <option value="PUA - Anual">PUA - Anual</option>
                                    <option value="PUP - Plurianual">PUP - Plurianual</option>
                                    <option value="PUSM - Semestral Mulher">PUSM - Semestral Mulher</option>
                                    <option value="PUAM - Anual Mulher">PUAM - Anual Mulher</option>
                                    <option value="PML - Mensal Lar">PML - Mensal Lar</option>
                                    <option value="PUSL - Semestral Lar">PUSL - Semestral Lar</option>
                                    <option value="PMM - Mensal Mulher">PMM - Mensal Mulher</option>
                                    <option value="OUTROS">OUTROS</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="w-full"></div>
                    <div>
                        <button className="w-28" onClick={onPesquisar}>Pesquisar</button>
                    </div>

                </div>
                <div className="flex flex-row items-center pl-5 ">
                    <div>Total:</div>
                    <div>&nbsp;{vendas.reduce((s, a) => s + a.valorPago, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</div>
                </div>
                <div className="overflow-auto p-5 scrollbar-none">
                    {loading ?
                        <Skeleton count={60} height={"30px"} />
                        :
                        <div className="flex flex-col">
                            <div className="tableFixHead">
                                <table cellPadding={2} cellSpacing={2} border={0}>
                                    <thead>
                                        <tr>
                                            <th align="left">Data Autenticação</th>
                                            <th align="right">Produto</th>
                                            <th align="right">Total</th>
                                            <th align="right">Valor</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {vendas && vendas.map((venda, index) => {
                                            return (
                                                <tr key={index} className="bg-white hover:bg-gray-100 cursor-pointer" onClick={() => showInfoVenda(venda)}>
                                                    <td align="left">{moment(venda.dataAutenticacao).format("DD/MM/YYYY")}</td>
                                                    <td align="right">{venda.produto}</td>
                                                    <td align="right">{venda.totalProduto}</td>
                                                    <td align="right">{venda.valorPago.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                    <tfoot>
                                        <tr className="fundo-cinza">
                                            <td align="left"></td>
                                            <td align="right"><b>TOTAIS:</b></td>
                                            <td align="right">{vendas.reduce((s, a) => s + a.totalProduto, 0)}</td>
                                            <td align="right">{vendas.reduce((s, a) => s + a.valorPago, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </LogadoLayout>

    );

}