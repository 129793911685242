import React, { useEffect, useState } from "react";
import { FiLayers } from "react-icons/fi";
import Skeleton from 'react-loading-skeleton';
import { IMask } from "react-imask";
import { listaRelatorio } from "../../api/DataApi";
import LogadoLayout from "../../componentes/logado";
import moment from 'moment';
import ptBR from 'date-fns/locale/pt-BR';
import DatePicker, { registerLocale } from "react-datepicker";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import './style.css';
registerLocale('ptBR', ptBR);


export default function RelatorioPagina() {
    const [loading, setLoading] = useState(false);
    const [sigpfTotalizador, setSigpfTotalizador] = useState([]);
    const [sigpfeReporteDiario, setSigpfeReporteDiario] = useState([]);
    const [sigpfeReporteSevs, setSigpfeReporteSevs] = useState([]);
    const [errorData, setErorData] = useState(null);

    const [filtro, setFiltro] = useState({
        dataPesquisa: new Date(),
    });

    const onPesquisa = () => {
        let dataPesquisaFiltro = moment(filtro.dataPesquisa, "DD/MM/YYYY").format("YYYY-MM-DD");
        console.log(dataPesquisaFiltro)
        setLoading(loading);
        listaRelatorio(callBackListaRelatorio, dataPesquisaFiltro);
    }


    const callBackListaRelatorio = (response) => {
        console.log("Pesquisar", response);

        if (response) {
            setSigpfTotalizador(response.sigpfTotalizador);
            setSigpfeReporteDiario(response.sigpfeReporteDiario);
            setSigpfeReporteSevs(response.sigpfeReporteSevs);

        }

        setLoading(false);
    }

    const money = IMask.createPipe({
        mask: 'num',
        blocks: {
            num: {
                mask: Number,
                signed: false,
                thousandsSeparator: '.',
                scale: 2,
                normalizeZeros: true,
                padFractionalZeros: true,
            }
        }

    });

    const onAtualizaData = (date, tipo) => {
        setFiltro({ ...filtro, [tipo]: date });
    }

    const Cores = (numero) => {
        if ((numero % 2) == 0) {
            return true;
        }
        else {
            return false;
        }
    }

    let totalqtd_PUA = 0;
    let totalqtd_PUS = 0;
    let totalqtd_PM = 0;
    let totalqtd_AG = 0;
    let totalqtd_CCA = 0;
    let totalqtd_UL = 0;
    let totalvalor_PUA = 0;
    let totalvalor_PUS = 0;
    let totalvalor_PM = 0;
    let totalReporteSevs = 0;
    let totalReporteSevsVendas = 0;
    let totalReporteSevsDispersao = 0;
    const monthNames =
        ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
            "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
    //let newDate = new Date()
    //let date = dataPesquisa.getDate();
    let month = filtro.dataPesquisa.getMonth() + 1;
    let year = filtro.dataPesquisa.getFullYear();


    return (
        <>
            <LogadoLayout titulo="Relatório Comercial"
                icon={<FiLayers size={30} />}>
                <div className="flex flex-row items-center ml-4 mb-4 mt-4 mr-4">
                    <div className="mr-3">Data: </div>
                    <div className="mr-3">
                        <DatePicker
                            locale="ptBR"
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            selected={filtro.dataPesquisa}
                            onChange={(date) => onAtualizaData(date, 'dataPesquisa')}
                            className={errorData} />
                    </div>
                    <div className="w-full"></div>
                    <div>
                        <button className="w-28" onClick={onPesquisa}>Pesquisar</button>
                    </div>

                </div>

                <div className="overflow-auto p-5 scrollbar-none">
                    {loading ?
                        <Skeleton count={60} height={"30px"} />
                        :
                        <>
                            {sigpfTotalizador && sigpfTotalizador.length > 0
                                && sigpfeReporteSevs && sigpfeReporteSevs.length > 0 ?
                                <div className="flex flex-col">
                                    <div style={{ minWidth: 1200 }}>
                                        
                                        <table id="table-to-xls" class='tg' cellPadding={2} cellSpacing={2} border={0}>
                                            <thead>
                                                <tr>
                                                    <th class="tg-oe15"></th>
                                                    <th class="tg-rcw7" colspan="7">QUANT</th>
                                                    <th class="tg-oe15"></th>
                                                    <th class="tg-rcw7" colspan="4">VALOR</th>
                                                    <th class="tg-oe15"></th>
                                                    <th class="tg-rcw7" colspan="7">%</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="tg-kaak"></td>
                                                    <td class="tg-kdfh">PUA</td>
                                                    <td class="tg-kdfh">PUS</td>
                                                    <td class="tg-kdfh">PM</td>
                                                    <td class="tg-kdfh">TOTAL</td>
                                                    <td class="tg-f0cj">AG</td>
                                                    <td class="tg-f0cj">CCA</td>
                                                    <td class="tg-f0cj">UL</td>
                                                    <td class="tg-kaak"></td>
                                                    <td class="tg-kdfh">PUA</td>
                                                    <td class="tg-kdfh">PUS</td>
                                                    <td class="tg-kdfh">PM</td>
                                                    <td class="tg-kdfh">TOTAL</td>
                                                    <td class="tg-kaak"></td>
                                                    <td class="tg-kdfh">PUA</td>
                                                    <td class="tg-kdfh">PUS</td>
                                                    <td class="tg-kdfh">PM</td>
                                                    <td class="tg-f0cj">AG</td>
                                                    <td class="tg-f0cj">CCA</td>
                                                    <td class="tg-f0cj">UL</td>

                                                </tr>
                                                <tr>
                                                    <td class='tg-tzeq'>TOTAL</td>
                                                    {sigpfTotalizador.map(sigpfTotalizador => {
                                                        totalqtd_PUA += sigpfTotalizador.qtd_PUA;
                                                        totalqtd_PUS += sigpfTotalizador.qtd_PUS;
                                                        totalqtd_PM += sigpfTotalizador.qtd_PM;
                                                        totalqtd_AG += sigpfTotalizador.ag;
                                                        totalqtd_CCA += sigpfTotalizador.cca;
                                                        totalqtd_UL += sigpfTotalizador.ul;
                                                        totalvalor_PUA += sigpfTotalizador.vlr_PUA;
                                                        totalvalor_PM += sigpfTotalizador.vlr_PM;
                                                        totalvalor_PUS += sigpfTotalizador.vlr_PUS;
                                                    })
                                                    }
                                                    <td class='tg-wpqz'>{totalqtd_PUA}</td>
                                                    <td class='tg-wpqz'>{totalqtd_PUS}</td>
                                                    <td class='tg-wpqz'>{totalqtd_PM}</td>
                                                    <td class='tg-wpqz'>{totalqtd_PUA + totalqtd_PUS + totalqtd_PM}</td>
                                                    <td class='tg-qwrb'>{totalqtd_AG}</td>
                                                    <td class='tg-qwrb'>{totalqtd_CCA}</td>
                                                    <td class='tg-qwrb'>{totalqtd_UL}</td>
                                                    <td class='tg-oe15'></td>
                                                    <td class='tg-wpqz'>{money(totalvalor_PUA.toString())}</td>
                                                    <td class='tg-wpqz'>{money(totalvalor_PUS.toString())}</td>
                                                    <td class='tg-wpqz'>{money(totalvalor_PM.toString())}</td>
                                                    <td class='tg-wpqz'>{money((totalvalor_PUA + totalvalor_PUS + totalvalor_PM).toString())}</td>
                                                    <td class='tg-oe15'></td>
                                                    <td class='tg-wpqz'>{((totalqtd_PUA) / (totalqtd_PUA + totalqtd_PUS + totalqtd_PM) * 100).toFixed(2)} %</td>
                                                    <td class='tg-wpqz'>{((totalqtd_PUS) / (totalqtd_PUA + totalqtd_PUS + totalqtd_PM) * 100).toFixed(2)} %</td>
                                                    <td class='tg-wpqz'>{((totalqtd_PM) / (totalqtd_PUA + totalqtd_PUS + totalqtd_PM) * 100).toFixed(2)} %</td>
                                                    <td class='tg-qwrb'>{((totalqtd_AG) / (totalqtd_AG + totalqtd_CCA + totalqtd_UL) * 100).toFixed(2)} %</td>
                                                    <td class='tg-qwrb'>{((totalqtd_CCA) / (totalqtd_AG + totalqtd_CCA + totalqtd_UL) * 100).toFixed(2)} %</td>
                                                    <td class='tg-qwrb'>{((totalqtd_UL) / (totalqtd_AG + totalqtd_CCA + totalqtd_UL) * 100).toFixed(2)} %</td>

                                                </tr>
                                                <tr>
                                                    <td class='tg-kaak'></td>
                                                    <td class='tg-kaak'></td>
                                                    <td class='tg-kaak'></td>
                                                    <td class='tg-kaak'></td>
                                                    <td class='tg-kaak'></td>
                                                    <td class='tg-kaak'></td>
                                                    <td class='tg-kaak'></td>
                                                    <td class='tg-kaak'></td>
                                                    <td class='tg-kaak'></td>
                                                    <td class='tg-kaak'></td>
                                                    <td class='tg-kaak'></td>
                                                    <td class='tg-kaak'></td>
                                                    <td class='tg-kaak'></td>
                                                    <td class='tg-kaak'></td>
                                                    <td class='tg-kaak'></td>
                                                    <td class='tg-kaak'></td>
                                                    <td class='tg-kaak'></td>
                                                    <td class='tg-kaak'></td>
                                                </tr>
                                                {sigpfTotalizador && sigpfTotalizador.map((totalizador, index) => {
                                                    return (
                                                        <tr>
                                                            <td class={(Cores(index) ? "tg-eois" : "tg-55sn")}>{monthNames[totalizador.mes + -1]}/{totalizador.ano}</td>
                                                            <td class={(Cores(index) ? "tg-kaak" : "tg-aks7")}>{totalizador.qtd_PUA}</td>
                                                            <td class={(Cores(index) ? "tg-kaak" : "tg-aks7")}>{totalizador.qtd_PUS}</td>
                                                            <td class={(Cores(index) ? "tg-kaak" : "tg-aks7")}>{totalizador.qtd_PM}</td>
                                                            <td class={(Cores(index) ? "tg-kaak" : "tg-aks7")}>{totalizador.qtd_total}</td>
                                                            <td class={(Cores(index) ? "tg-kaak" : "tg-aks7")}>{totalizador.ag}</td>
                                                            <td class={(Cores(index) ? "tg-kaak" : "tg-aks7")}>{totalizador.cca}</td>
                                                            <td class={(Cores(index) ? "tg-kaak" : "tg-aks7")}>{totalizador.ul}</td>
                                                            <td class='tg-oe15'></td>
                                                            <td class={(Cores(index) ? "tg-kaak" : "tg-aks7")}>{money(totalizador.vlr_PUA.toString())}</td>
                                                            <td class={(Cores(index) ? "tg-kaak" : "tg-aks7")}>{money(totalizador.vlr_PUS.toString())}</td>
                                                            <td class={(Cores(index) ? "tg-kaak" : "tg-aks7")}>{money(totalizador.vlr_PM.toString())}</td>
                                                            <td class={(Cores(index) ? "tg-kaak" : "tg-aks7")}>{money(totalizador.total_valor.toString())}</td>
                                                            <td class='tg-oe15'></td>
                                                            <td class={(Cores(index) ? "tg-kaak" : "tg-aks7")}>{((totalizador.qtd_PUA / totalizador.qtd_total) * 100).toFixed(2)}%</td>
                                                            <td class={(Cores(index) ? "tg-kaak" : "tg-aks7")}>{((totalizador.qtd_PUS / totalizador.qtd_total) * 100).toFixed(2)}%</td>
                                                            <td class={(Cores(index) ? "tg-kaak" : "tg-aks7")}>{((totalizador.qtd_PM / totalizador.qtd_total) * 100).toFixed(2)}%</td>
                                                            <td class={(Cores(index) ? "tg-kaak" : "tg-aks7")}>{((totalizador.ag / totalizador.qtd_total) * 100).toFixed(2)}%</td>
                                                            <td class={(Cores(index) ? "tg-kaak" : "tg-aks7")}>{((totalizador.cca / totalizador.qtd_total) * 100).toFixed(2)}%</td>
                                                            <td class={(Cores(index) ? "tg-kaak" : "tg-aks7")}>{((totalizador.ul / totalizador.qtd_total) * 100).toFixed(2)}%</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        <div style={{ paddingTop: 10 }}>
                                        <ReactHTMLTableToExcel
                                            id="button-download"
                                            className="button"
                                            table="table-to-xls"
                                            filename="Relatorio_Comercial"
                                            sheet="tablexls"
                                            buttonText="Exportar XLS" />
                                        </div>
                                        
                                    </div>
                                    <div style={{ alignItems: 'flex-start', paddingTop: 20 }} className="flex">
                                        <div>
                                            <table class='tg2' cellPadding={2} cellSpacing={2} border={0} style={{ width: 650 }}>
                                                <thead><tr>
                                                    <th class='tg-2nun' colspan='2'>Fechamento do mês</th>
                                                    <th class='tg-trfl' colspan='3'> {year} - {monthNames[month + -1].toUpperCase()} </th>

                                                </tr></thead>
                                                <tbody>
                                                    <tr>
                                                        <td class='tg-1jmn'>Região</td>
                                                        <td class='tg-x1bh'>Vendas dia anterior</td>
                                                        <td class='tg-ttc1'>R$ Dia anterior</td>
                                                        <td class='tg-ttc1'>Vendas Acumuladas</td>
                                                        <td class='tg-ttc1'>R$ Acumulado</td>
                                                    </tr>
                                                    {sigpfeReporteDiario && sigpfeReporteDiario.map((reporteDiario, index) => {
                                                        return (
                                                            <tr>
                                                                <td class={(Cores(index) ? "tg-oe15" : "tg-axoc")}>{reporteDiario.regiao}</td>
                                                                <td class={(Cores(index) ? "tg-wk8r" : "tg-y5hu")}>{reporteDiario.qtd_vendas_dia}</td>
                                                                <td class={(Cores(index) ? "tg-wk8r" : "tg-y5hu")}>{money(reporteDiario.vendas_dia.toString())}</td>
                                                                <td class={(Cores(index) ? "tg-wk8r" : "tg-y5hu")}>{reporteDiario.qtd_vendas_mes}</td>
                                                                <td class={(Cores(index) ? "tg-wk8r" : "tg-y5hu")}>{money(reporteDiario.vendas_mes.toString())}</td>
                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div style={{ alignItems: 'flex-start', paddingTop: 20 }} className="flex">
                                        <div style={{ paddingLeft: 0 }}>
                                            <table class='tg3' cellPadding={2} cellSpacing={2} border={0} style={{ width: 436 }}>

                                                <thead>
                                                    <tr>
                                                        <th class='tg-nj80' colspan='6'>Dispersão por agências {monthNames[month + -1]}/{year}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sigpfeReporteSevs.map(sigpfeReporteSevs => {
                                                        totalReporteSevs += sigpfeReporteSevs.total;
                                                        totalReporteSevsVendas += sigpfeReporteSevs.vendas;
                                                        totalReporteSevsDispersao += sigpfeReporteSevs.dispersao;
                                                    })}

                                                    {sigpfeReporteSevs && sigpfeReporteSevs.map((reporteSev, index) => {
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td class={(Cores(index) ? "tg-6zfa" : "tg-5a24")}>{reporteSev.regiao}</td>
                                                                    <td class={(Cores(index) ? "tg-6zfa" : "tg-5a24")}>{reporteSev.total}</td>
                                                                    <td class={(Cores(index) ? "tg-6zfa" : "tg-5a24")}>{reporteSev.vendas}</td>
                                                                    <td class={(Cores(index) ? "tg-6zfa" : "tg-5a24")}>{((reporteSev.vendas / reporteSev.total) * 100).toFixed(2)}%</td>
                                                                    <td class={(Cores(index) ? "tg-6zfa" : "tg-5a24")}>{reporteSev.dispersao}</td>
                                                                    <td class={(Cores(index) ? "tg-6zfa" : "tg-5a24")}>{((reporteSev.dispersao / reporteSev.total) * 100).toFixed(2)}%</td>
                                                                </tr>

                                                            </>
                                                        )
                                                    })}

                                                    <tr>
                                                        <td class='tg-fmx0'>Brasil</td>
                                                        <td class='tg-fmx0'>{totalReporteSevs}</td>
                                                        <td class='tg-fmx0'>{totalReporteSevsVendas}</td>
                                                        <td class='tg-fmx0'>{((totalReporteSevsVendas / totalReporteSevs) * 100).toFixed(2)}%</td>
                                                        <td class='tg-fmx0'>{totalReporteSevsDispersao}</td>
                                                        <td class='tg-fmx0'>{((totalReporteSevsDispersao / totalReporteSevs) * 100).toFixed(2)}%</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div><b>Não consta nenhuma informação</b></div>
                            }
                        </>
                    }
                </div>

            </LogadoLayout>
        </>
    );

}