import React, { useContext, useEffect } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";

export default function MenuPage() {
    const usuarioLogado = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!usuarioLogado || !usuarioLogado?.usuario || !usuarioLogado?.usuario?.logado) {
            navigate("/");
        }
    }, [])

    const onLogout = () => {
        usuarioLogado.setUsuario(null);

        navigate("/");
    }

    return (
        <ProSidebar className="pro-sidebar-login " >
            <SidebarContent>
                <Menu className="text-white grid grid-flow-row" style={{width: "15vw"}}>
                    <SubMenu title="Segurança" style={{ display: ((usuarioLogado?.usuario?.listaFuncoes.find(f => f.link === 'usuario') || usuarioLogado?.usuario?.listaFuncoes.find(f => f.link === 'perfil'))) ? 'block' : 'none' }}>
                        <MenuItem style={{ display: (usuarioLogado?.usuario?.listaFuncoes.find(f => f.link === 'usuario')) ? 'block' : 'none' }}>
                            Usuários
                            <Link to="/usuario" />
                        </MenuItem>
                        <MenuItem style={{ display: (usuarioLogado?.usuario?.listaFuncoes.find(f => f.link === 'perfil')) ? 'block' : 'none' }}>
                            Perfil
                            <Link to="/perfil" />
                        </MenuItem>
                    </SubMenu>
                    <MenuItem style={{ display: (usuarioLogado?.usuario?.listaFuncoes.find(f => f.link === 'vendas')) ? 'block' : 'none' }}>
                        Consulta Propostas Integrador
                        <Link to="/vendas" />
                    </MenuItem>
                    <MenuItem>
                        Relatório analítico
                        <Link to="/vendasUnidades" />
                    </MenuItem>
                    <MenuItem>
                        Consulta sintética
                        <Link to="/vendasUnidadesAgrupadas" />
                    </MenuItem>
                    <MenuItem style={{ display: (usuarioLogado?.usuario?.listaFuncoes.find(f => f.link === 'perfil')) ? 'block' : 'none' }}>
                        Carga
                        <Link to="/carga" />
                    </MenuItem>
                    <MenuItem style={{ display: (usuarioLogado?.usuario?.listaFuncoes.find(f => f.link === 'comercial')) ? 'block' : 'none' }}>
                        Relatório Comercial
                        <Link to="/relatorio" />
                    </MenuItem>
                    <MenuItem>
                        Políticas e Normativos
                        <Link to="/normativos" />
                    </MenuItem>
                    <MenuItem style={{ display: (usuarioLogado?.usuario?.listaFuncoes.find(f => f.link === 'governanca')) ? 'block' : 'none' }}>
                        Editar normativos
                        <Link to="/normativos/editar" />
                    </MenuItem>
                    <MenuItem>
                    Vendas por data de autenticação
                        <Link to="/relatorioTaspjunc"/>
                    </MenuItem>  

                    <SubMenu title="Painel" style={{ display: (usuarioLogado?.usuario?.listaFuncoes.find(f => f.link === 'painel')) ? 'block' : 'none' }}>
                        <MenuItem >
                            Painel TV
                            <Link to="/painel" />
                        </MenuItem>
                        <MenuItem style={{ display: (usuarioLogado?.usuario?.listaFuncoes.find(f => f.link === 'perfil')) ? 'block' : 'none' }}>
                            Lista das Telas
                            <Link to="/telas" />
                        </MenuItem>
                    </SubMenu>
                </Menu>
            </SidebarContent>
        </ProSidebar>
    )
}