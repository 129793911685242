
import React, { Component } from "react";
import Chart from "react-apexcharts";


class RadialBar extends Component {
  constructor(props) {
    super(props);

    var colors = [
      '#4FB852'
    ]

    this.state = {

      series: [this.props.porcentagem],
      options: {
        chart: {
          type: 'radialBar',
          offsetY: -30,
          sparkline: {
            enabled: true
          }
        },
        colors: colors,
        plotOptions: {
          radialBar: {
            startAngle: -100,
            endAngle: 100,
            track: {
              background: "#e7e7e7",
              strokeWidth: '100%',
              margin: 50, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 1,
                left: 0,
                color: '#999',
                opacity: 1,
                blur: 2
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                color: '#4FB852',
                fontSize: '200px'
              }
            }
          }
        },
        grid: {
          padding: {
            top: 0
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 45, 53, 91]
          },
        },
        colors: colors,
        labels: ['Average Results'],
      },
    };
  }
  render() {
    return (
      <div  style={{ height: "calc(100vh - 90px)" }}>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="radialBar"
          height="160%"
          width="100%"
        />
      </div>
    );
  }
}

export default RadialBar;