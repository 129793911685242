import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../componentes/context/AppContext";
import { useNavigate } from "react-router-dom";
import LogadoLayout from "../../componentes/logado";
import { AiOutlineUser, AiOutlineUsergroupAdd } from "react-icons/ai";
import { listaAniversariantes } from "../../api/DataApi";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel from "../../componentes/carousel";

export default function HomePagina() {
    const [aniversariantes, setAniversariantes] = useState();
    const usuarioLogado = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!usuarioLogado || !usuarioLogado?.usuario || !usuarioLogado?.usuario?.logado) {
            navigate("/");
        }

    }, [usuarioLogado])

    useEffect(() => {
        listaAniversariantes(callBackListaAniversariantes);
    }, []);

    const callBackListaAniversariantes = (response) => {
        if (response)
            setAniversariantes(response.listaAniversariantes);
    }

    return (
        <>
            <LogadoLayout icon={<AiOutlineUser size={20} />} titulo="" valorOverflow="hidden">
                <div className="flex flex-col w-full p-4" >
                    <div>
                        <Carousel />
                    </div>

                    <div className="flex flex-row justify-between">
                        <div className="div-container">
                            <h1 className="mb-3">Aniversariantes do Mês</h1>
                            <div className="flex flex-col" style={{ height: "12vh", overflow: "auto" }}>
                                {aniversariantes && aniversariantes.map(c => {
                                    return (
                                        <div className="flex flex-row justify-between">
                                            <div>{c.nome}</div>
                                            <div>{c.dia}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </LogadoLayout>
        </>
    )
}