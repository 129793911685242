import React, { useEffect, useState } from "react";
import { vendasTotalDia, telasAtivas } from "../../../api/DataApi";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';
import MenuPainel from "../componentes/menuPainel"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
registerLocale('ptBR', ptBR);

export default function TotalVendasDia() {
  const [telas, setTelas] = useState([]);
  const [quantidade, setTotal] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    telasAtivas(callBackTelasAtivas)
  }, []);

  useEffect(() => {
    setTimeout(() => {
      nextTela()
    }, 60000);
  }, []);

  const callBackTelasAtivas = (response) => {
    setLoading(false);
    if (response) {
      setTelas(response.telasAtivas);
    }
  }

  function nextTela() {
    {
      let num = telas.length;
      let id = parseInt(location.state.id);

      let indice;
      setTelas((state) => {

        state.map((item, i) => {
          if (id >= num) {
            i = 0
            id = 0
          }
          if (i == id) {
            const { url } = item;
            indice = i++;
            return (
              navigate('../efetividadeRegiao', { state: { id: 1, name: 'sabaoon' } })
            )
          }
        })
      });
    }
  }

  useEffect(() => {
    vendasTotalDia(callBackVendasTotalDia);
  }, []);

  const callBackVendasTotalDia = (response) => {
    setLoading(false);
    if (response) {
      setTotal(response.quantidade);
    }
  }

  return (
    <>
      <div className="flex flex-col" style={{ height: "calc(100vh)", overflow: "hidden" }}>
        {telas.map((item, i) => {
          const { descricao, nome } = item;
          if (nome == "TotalVendasDia") {
            return (
              <MenuPainel titulo={descricao} />
            )
          }
        })
        }
        <div className="flex flex-row h-full grad1">
          <div className="flex-row h-full w-full justify-between">
            <p align="center" className="p-painel" >{quantidade}</p>
          </div>
        </div>
      </div>
    </>
  )
}