import React, { useEffect, useState, Component } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';
import LogoCaixa from '../../imagens/caixa_assistencia_2d_vertical_negativa.png';
import { useNavigate } from "react-router-dom";
import { telasAtivas } from "../../api/DataApi";
import Skeleton from 'react-loading-skeleton';


registerLocale('ptBR', ptBR);

export default function PainelTV() {

    const navigate = useNavigate();    

    const goHome = () => {
        navigate("../home");
    }
    const [loading, setLoading] = useState(false);
    const [telas, setTelas] = useState([]);
    let Tela = [];

    useEffect( () => {
        telasAtivas(callBackTelasAtivas)   
    }, []);

   useEffect(() => {
        setTimeout(() => {
        nextTela()
        }, 3000);
      }, []);

    const callBackTelasAtivas = (response) => {
        if (response) {
            setTelas(response.telasAtivas);
            console.log("TELAS",telas);
        }
          setLoading(false);
    }

    
    function nextTela() {
        {       
            let indice;
            setTelas((state) => {
                console.log("STATE",state);
                state.map((item, i) => {
                    let num = state.length;
                    if (num > 0) {
                        if (i == 0) {
                            //url_teste = item;
                            const { url } = item;
                            console.log("url_teste: ",item.url);
                            indice = i++;
                            console.log("indice: ",indice);
                            return (
                                navigate('../conversao',{state:{id:1,name:'sabaoon'}})
                                //navigate('../' + url ,{state:{id:i,name:'sabaoon'}})
                            )
                        }
                    }
                })
                
              });

        }

    }



    return (
        <>
            <div className="flex flex-col" style={{ height: "calc(100vh)", overflow: "hidden" }}>
                <div className="bg-caixa flex flex-row w-full items-center shadow p-5 justify-between " style={{ height: "180px" }}>
                    <div >
                        <img src={LogoCaixa} style={{ width: "180px" }} onClick={goHome} className="cursor-pointer logo"></img>
                    </div>
                    
                </div>
                <div className="flex flex-row h-full">
                    <div className="w-full" style={{ height: "calc(90vh)", overflow: "hidden" }}>
                    {loading ?
                            <Skeleton count={60} height={"30px"}/>
                        :
                            <>
                             {telas && telas.length > 0 ?
                                <div className="flex flex-col">
                                   {telas && telas.map((telas, index) => {
                                                return (
                                                    <>
                                                        <div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                </div>
                                 
                            :
                                <div><b>Nenhuma Tela encontrada como ativa!</b></div>
                            }
                            </>
                        }
                    
                   

                    </div>
                </div>
            </div>
        </>
    )
}

