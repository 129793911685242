import React, {useContext, useEffect, useState} from "react";
import LogoCaixa from '../../imagens/caixa_assistencia_2d_vertical_negativa.png';
import { FaSistrix } from "react-icons/fa";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";

export default function Cabecalho(
    {
        icon, 
        titulo,
        opcao
    }) {
    const navigate = useNavigate();

    const [usuario, setUsuario] = useState();
    const usuarioLogado = useContext(AppContext);

    const onLogout = () => {
        usuarioLogado.setUsuario(null);

        navigate("/");
    }

    useEffect(() => {
        console.log("useEffect", usuarioLogado);
        if (usuarioLogado?.usuario?.usuario)
            setUsuario(usuarioLogado.usuario.usuario);
    }, [usuarioLogado]);

    const goHome = () => {
        navigate("/home");
    }

    return (
        <div className="bg-caixa text-3xs inline-flex items-center p-3 w-full sm:text-sm" style={{height: "11vh"}}>
            <div class="p-3 w-1/3 sm:w-auto">
                <img src={LogoCaixa} style={{width: "130px"}} onClick={goHome} className="cursor-pointer"></img>
            </div>
            <div class="grid w-2/3 h-14 sm:flex sm:flex-row sm:items-center sm:w-full sm:p-3">
                <div className="search-box p-3 w-full">
                    <input type="text" placeholder="buscar" className="search-txt" />
                    <a href="#">
                        <FaSistrix size={14} className="mr-3 ml-3"></FaSistrix>
                    </a>
                </div>
                {usuario && 
                    <div className="flex flex-row items-center p-2 w-full sm:flex-row-reverse sm:ml-4" style={{maxWidth: "max-content"}}>
                        <div className="flex flex-col mr-4 text-white w-3/4 sm:w-auto">
                            <div class="font-black">{usuario.nome}</div>
                            <div>{usuario.perfil}</div>
                            <div><a href="#" onClick={() => onLogout()}>Sair</a></div>
                        </div>
                        <div className="flex flex-col items-center w-1/4 sm:w-auto sm:mr-4 sm:w-auto">
                            <div className="flex flex-row justify-center items-center rounded-full bg-gray-100 w-5 h-5 text-black border-2 border-gray-500 sm:w-10 sm:h-10">
                                <div>
                                    {usuario.nome.substr(0,1).toUpperCase()}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}