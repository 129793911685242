import React, {useEffect, useState} from "react";
import Dialog from "../../componentes/dialog";
import { FiArrowDown, FiLayers, FiArrowUp } from "react-icons/fi";
import Cabecalho from "../../componentes/cabecalho";
import MenuPage from "../../componentes/menu";
import Skeleton from 'react-loading-skeleton';
import { IMaskInput, IMask } from "react-imask";
import { pesquisaVendasUnidades } from "../../api/DataApi";
import moment from 'moment';
import LogadoLayout from "../../componentes/logado";
import ptBR from 'date-fns/locale/pt-BR';
import DatePicker, {registerLocale} from "react-datepicker";

registerLocale('ptBR', ptBR);

export default function VendasUnidadesPagina() {
    const [loading, setLoading] = useState(false);
    const [vendas, setVendas] = useState([]);
    const [qtde, setQtde] = useState(0);
    const [total, setTotal] = useState(0);
    const [openInfoVenda, setOpenInfoVenda] = useState(false);
    const [infoVenda, setInfoVenda] = useState();
    const [validacao, setValidacao] = useState(null);
    const [sortConfig, setSortConfig] = useState(null);
    const [errorData, setErorData] = useState(null);

    const [filtro, setFiltro] = useState({
        dataInicio: new Date(),
        dataFin: new Date(),
        agencia: "",
        sr: "",
        sev: "",
        regiao: "",
        coD_UL: "",
        canal:""
    });

    const onAtualizaFiltro = (e) => {
        setFiltro({...filtro, [e.target.name]: e.target.value});
    }

    const onPesquisar = () => {

        let dataInicio = moment(filtro.dataInicio, "DD/MM/YYYY").format("YYYY-MM-DD");
        let dataFin = moment(filtro.dataFin, "DD/MM/YYYY").format("YYYY-MM-DD");

        let agencia = filtro.agencia === "" ? undefined : filtro.agencia;
        let sr = filtro.sr === "" ? undefined : filtro.sr;
        let sev = filtro.sev === "" ? undefined : filtro.sev;
        let regiao = filtro.regiao === "" ? undefined : filtro.regiao;
        let coD_UL = filtro.coD_UL === "" ? undefined : filtro.coD_UL;
        //let canal = filtro.canal === "" ? undefined : filtro.canal;
        

        let dias =  moment(dataFin).diff(moment(dataInicio), 'days');

        if(dias <= 60) {
            setLoading(true);
            setValidacao(null);
            pesquisaVendasUnidades(dataInicio, dataFin, agencia, sr, sev, coD_UL, regiao, callBackPesquisarVendas)

        } else {
            setValidacao("Os intervalos das datas devem ser menor a 60 dias.")
            setErorData("bg-red-300")
        }

    }

    const showInfoVenda = (venda) => {

        if (venda) {
            setOpenInfoVenda(true);
            setInfoVenda(venda);
        }
    }

    const callBackPesquisarVendas = (response) => {
        console.log("Pesquisar", response);

        if (response) {
            setQtde(response.quantidade);
            setVendas(response.vendas);
            setTotal(response.total)
        }

        setLoading(false);
    }

    const moneyPipe = IMask.createPipe({
        mask: 'R$ num',
        blocks : {
            num : {
                mask: Number,
                signed: false,
                thousandsSeparator: '.',
                scale: 2,
                normalizeZeros: true,
                padFractionalZeros: true,
            }
        }

    });

    const onAtualizaData = (date, tipo) => {
        setFiltro({...filtro, [tipo]: date});
    }

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
          sortConfig &&
          sortConfig.key === key &&
          sortConfig.direction === 'ascending'
        ) {
          direction = 'descending';
        }
        setSortConfig({ key, direction });

        setVendas(sortedItems);
    };

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
          return;
        }
        return sortConfig.key === name ? (sortConfig.direction === "ascending" ? <FiArrowDown className="d-inline"/> : <FiArrowUp className="d-inline"/>): undefined;
    };

    const sortedItems = React.useMemo(() => {
        let sortableItems = [...vendas];
        if (sortConfig !== null) {
          sortableItems.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
              return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
              return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
          });
        }
        return sortableItems;
      }, [vendas, sortConfig]);

    return (
        <>
        <LogadoLayout titulo="Relatório analítico"
                      icon={<FiLayers size={30}/>}>
                    <div className="w-full grid sm:flex sm:flex-row sm:items-center ml-4 mb-4 mt-4 mr-4">
                        <div className="mr-3">Data Início: </div>
                        <div className="mr-3">
                        <DatePicker 
                                locale="ptBR"
                                dateFormat="dd/MM/yyyy"
                                selected={filtro.dataInicio} 
                                onChange={(date) => onAtualizaData(date, 'dataInicio')}
                                className={errorData}/>                           
                        </div>

                        <div className="mr-3">Data Fim: </div>
                        <div className="mr-3">
                            <DatePicker 
                                locale="ptBR"
                                dateFormat="dd/MM/yyyy"
                                selected={filtro.dataFin} 
                                onChange={(date) => onAtualizaData(date, 'dataFin')}
                                className={errorData}/> 
                          
                        </div>
                        <div>
                            {validacao && <span className="text-red-500">{validacao}</span>}
                        </div>
                    </div>

                    <div className="grid grid-flow-row align-middle sm:flex sm:flex-row sm:items-center ml-4 mb-4 mt-4 mr-4">
                        <div className="flex flex-row items-center mb-3">
                           <div class="sm:flex sm:flex-row sm:items-center">
                                <div className="mr-3">Agência: </div>
                                <div className="mr-6"><input type="text" className="w-28" name="agencia" value={filtro.agencia} onChange={(e) => onAtualizaFiltro(e)}></input></div>
                            </div>
                            <div class="sm:flex sm:flex-row sm:items-center">
                                <div className="mr-3">SEV: </div>
                                <div className="mr-6"><input type="text" className="w-28" name="sev" value={filtro.sev} onChange={(e) => onAtualizaFiltro(e)}></input></div>
                            </div>
                        </div>
                        <div className="flex flex-row items-center mb-3">
                            <div class="sm:flex sm:flex-row sm:items-center">
                                <div className="mr-3">SR: </div>
                                <div className="mr-6"><input type="text" className="w-28" name="sr" value={filtro.sr} onChange={(e) => onAtualizaFiltro(e)}></input></div>
                            </div>
                            <div class="sm:flex sm:flex-row sm:items-center">
                                <div className="mr-3">Código UL: </div>
                                <div className="mr-6"><input type="text" className="w-28" name="coD_UL" value={filtro.coD_UL} onChange={(e) => onAtualizaFiltro(e)}></input></div>
                            </div>
                        </div>
                        <div className="flex flex-row items-center mb-3">
                            <div class="sm:flex sm:flex-row sm:items-center">
                                <div className="mr-3">Região: </div>
                                <div className="mr-6">
                                    <select className="w-32" name="regiao" onChange={(e) => {onAtualizaFiltro(e)}}>
                                        <option value="">Todos</option>
                                        <option value="1">Centro-Oeste</option>
                                        <option value="2">Nordeste</option>
                                        <option value="3">Norte</option>
                                        <option value="4">São Paulo - Capital e Litoral</option>
                                        <option value="5">São Paulo - Interior</option>
                                        <option value="6">Sudeste</option>
                                        <option value="7">Sul</option>
                                    </select>
                                </div>
                            </div>
                            <div class="sm:flex sm:flex-row sm:items-center">
                                <div className="mr-3">Canal: </div>
                                <div className="mr-6">
                                    <select className="w-32" name="canal" onChange={(e) => {onAtualizaFiltro(e)}}>
                                    <option value="">Todos</option>
                                    <option value="SIGPF">SIGPF</option>
                                    <option value="SICAP">SICAP</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="w-full"></div>
                        <div>
                            <button className="w-28" onClick={onPesquisar}>Pesquisar</button>
                        </div>

                    </div>

                    <div className="flex flex-row items-center pl-5 ">
                        <div >Quantidade:</div>
                        <div className="mr-6">{qtde}</div>

                        <div >Total:</div>
                        <div>{moneyPipe(total.toString())}</div>
                    </div>
                    <div className="overflow-auto p-5 scrollbar-none">
                        {loading ?
                            <Skeleton count={60} height={"30px"}/>
                        :
                        <div className="sm:flex sm:flex-col">
                            <div className="tableFixHead">
                                <table cellPadding={2} cellSpacing={2} border={0}>
                                    <thead>
                                        <tr>
                                            <th align="left">Data Autenticação</th>
                                            <th align="right" 
                                                onClick={() => requestSort('numeroProposta')}
                                                className="cursor-pointer">
                                                    Número proposta {getClassNamesFor('numeroProposta')}
                                            </th>
                                            <th align="right" 
                                                onClick={() => requestSort('valorRecebido')}
                                                className="cursor-pointer">
                                                    Valor {getClassNamesFor('valorRecebido')}
                                            </th>
                                            <th align="right" 
                                                onClick={() => requestSort('ag')}
                                                className="cursor-pointer">
                                                AG {getClassNamesFor('ag')}
                                            </th>
                                            <th align="right" 
                                                onClick={() => requestSort('sev')}
                                                className="cursor-pointer">
                                                SEV {getClassNamesFor('sev')}
                                            </th>
                                            <th align="right" 
                                                onClick={() => requestSort('sr')}
                                                className="cursor-pointer">
                                                SR {getClassNamesFor('sr')}
                                            </th>
                                            <th align="left" 
                                                onClick={() => requestSort('tipo')}
                                                className="cursor-pointer">
                                                Plano {getClassNamesFor('tipo')}
                                            </th>
                                            <th align="left" 
                                                onClick={() => requestSort('venda')}
                                                className="cursor-pointer">
                                                Venda {getClassNamesFor('venda')}
                                            </th>
                                            <th align="right" 
                                                onClick={() => requestSort('coD_UL')}
                                                className="cursor-pointer">
                                                Código UL {getClassNamesFor('coD_UL')}
                                            </th>
                                            <th align="left" 
                                                onClick={() => requestSort('uf')}
                                                className="cursor-pointer">
                                                UF (AG) {getClassNamesFor('uf')}
                                            </th>
                                            <th align="left" 
                                                onClick={() => requestSort('regiao')}
                                                className="cursor-pointer">
                                                Região (SR) {getClassNamesFor('regiao')}
                                            </th>
                                            <th align="left" 
                                                onClick={() => requestSort('canal')}
                                                className="cursor-pointer">
                                                Canal {getClassNamesFor('canal')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {vendas && vendas.map((venda, index) => {
                                            return (
                                                <tr key={index} className="bg-white hover:bg-gray-100 cursor-pointer" onClick={() => showInfoVenda(venda)}>
                                                    <td align="left">{moment(venda.data).format("DD/MM/YYYY")}</td>
                                                    <td align="right">{venda.numeroProposta}</td>
                                                    <td align="right">{moneyPipe(venda.valorRecebido.toString())}</td>
                                                    <td align="right">{venda.ag}</td>
                                                    <td align="right">{venda.sev}</td>
                                                    <td align="right">{venda.sr}</td>
                                                    <td align="left">{venda.tipo}</td>
                                                    <td align="left">{venda.venda}</td>
                                                    <td align="right">{venda.coD_UL}</td>
                                                    <td align="left">{venda.uf}</td>
                                                    <td align="left">{venda.regiao}</td>
                                                    <td align="left">{venda.canal}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        }
                    </div>
        </LogadoLayout>  

            <Dialog open={openInfoVenda}
                    title={
                        <>
                        <div className="flex flex-row">
                        <div>Detalhes da Venda</div>                    
                        </div>
                        </>
                    }
                    onClose={() => setOpenInfoVenda(false)}
                    primaryButtonText=""
                    width="850px">
                <div>
                    {infoVenda &&
                    <>
                        <table cellPadding={2} cellSpacing={2} border={0} className="m-4">
                            <tr>
                                <td>Data:</td>
                                <td>{moment(infoVenda.data).format("DD/MM/YYYY")}</td>
                            </tr> 
                            <tr>
                                <td>Número proposta:</td>
                                <td>{infoVenda.numeroProposta}</td>
                            </tr>                               
                            <tr>
                                <td>Valor:</td>
                                <td>{moneyPipe(infoVenda.valorRecebido.toString())}</td>
                            </tr>   
                            <tr>
                                <td>Agência:</td>
                                <td>{infoVenda.ag}</td>
                            </tr>  
                            <tr>
                                <td>SR:</td>
                                <td>{infoVenda.sr}</td>
                            </tr>                                                                                 
                            <tr>
                                <td>SEV:</td>
                                <td>{infoVenda.sev}</td>
                            </tr>   
                            <tr>
                                <td>Tipo:</td>
                                <td>{infoVenda.tipo}</td>
                            </tr>                                                       
                            <tr>
                                <td>Venda:</td>
                                <td>{infoVenda.venda}</td>
                            </tr>                            
                            <tr>
                                <td>Código UL:</td>
                                <td>{infoVenda.coD_UL}</td>
                            </tr>  
                            <tr>
                                <td>UF:</td>
                                <td>{infoVenda.uf}</td>
                            </tr>                                                                                
                            <tr>
                                <td>Região:</td>
                                <td>{infoVenda.regiao}</td>
                            </tr>  
                            <tr>
                                <td>Canal:</td>
                                <td>{infoVenda.canal}</td>
                            </tr>  
                        </table>
                    </>
                    }
               </div>
            </Dialog>            
        </>
    );

}