import axios from "axios";

const api = axios.create({
  baseURL: "https://intranet.caixaassistencia.com.br/backend",
});

/*
const api = axios.create({
  baseURL: "http://localhost:5000",
});
*/


/*
const api = axios.create({
  baseURL: "http://172.18.0.83:80/backend",
});
*/
export const login = (usuario, senha, callback) => {
  let data = {
    usuario: usuario,
    senha: senha
  }

  api.post("/usuario/login", data).then((response) => {
      callback(response.data);
  })
  .catch((err) => {
    callback(err.response.data);
  })
}

export const resetarSenha = (id, callback) =>
{
  let data = {
    id: id
  }

  console.log(data);

  api.post("/usuario/resetar-senha", data).then((response) => {
      callback(response.data);
  })
  .catch((err) => {
    callback(err.response.data);
  })
}

export const listaUsuarios = (pesquisa, callback) => {
  let data = {
    pesquisa: pesquisa
  }

  console.log(data);

  api.post("/usuario/lista-usuarios", data).then((response) => {
      callback(response.data);
  })
  .catch((err) => {
    callback(err.response.data);
  })
}

export const listaPerfil = (callback) => {
  api.get("/perfil/lista-perfil").then((response) => {
    callback(response.data);
  })
  .catch((err) => {
    console.log(err);

    var response = {
      codigo: -1,
      mensagem: err
    }

    callback(response);    
  })  
}

export const listaFuncao = (callback) => {
  api.get("/funcao/lista-funcoes").then((response) => {
    callback(response.data);
  })
  .catch((err) => {
    console.log(err);

    var response = {
      codigo: -1,
      mensagem: err
    }

    callback(response);    
  })  
}

export const listaAniversariantes = (callback) => {
  api.get("/aniversario/lista-aniversariantes").then((response) => {
    callback(response.data);
  })
  .catch((err) => {
    console.log(err);

    var response = {
      codigo: -1,
      mensagem: err
    }

    callback(response);    
  })  
}

export const retornaUsuarioPeloId = (id, callback) => {
  let data = {
    id: id
  }

  console.log(data);

  api.post("/usuario/usuario", data).then((response) => {
      console.log("retornaUsuarioPeloId", response);

      callback(response.data);
  })
  .catch((err) => {
    callback(err);
  })
}

export const retornaPerfilPeloId = (id, callback) => {
  let data = {
    id: id
  }

  console.log(data);

  api.post("/perfil/perfil", data).then((response) => {
      console.log("retornaPerfilPeloId", response);

      callback(response.data);
  })
  .catch((err) => {
    callback(err);
  })
}

export const salvarPerfil = (perfil, funcoes = [], callback) => {
  let data = {
    id: perfil.id,
    descricao: perfil.descricao,
    funcoes: funcoes
  }

  console.log(data);

  api.post("/perfil/salvar", data).then((response) => {
      callback(response.data);
  })
  .catch((err) => {
    callback(err.response.data);
  })
}

export const salvarUsuario = (usuario, callback) => {
  let data = {
    nick: usuario.nick,
    id: usuario.id,
    idperfil: usuario.idperfil,
    email: usuario.email,
    nome: usuario.nome,
    celular: usuario.celular,
    telefone: usuario.telefone,
    situacao: usuario.situacao
  }

  console.log(data);

  api.post("/usuario/salvar", data).then((response) => {
      callback(response.data);
  })
  .catch((err) => {
    callback(err.response.data);
  })
}

export const alterarSenha = (id, senha, callback) => {
  let data = {
    id: id,
    senha: senha
  }

  console.log(data);

  api.post("/usuario/alterar-senha", data).then((response) => {
      callback(response.data);
  })
  .catch((err) => {
    callback(err.response.data);
  })
}

export const pesquisaVendas = (datavenda, pesquisa, situacao, callback) => {
  let data = {
    datavenda: datavenda,
    pesquisa: pesquisa,
    situacao: situacao
  }

  console.log(data);

  api.post("/venda/lista-vendas", data).then((response) => {
      callback(response.data);
  })
  .catch((err) => {
    callback(err.response.data);
  })
}

export const retornaAnexoPeloId = (id, callback) => {
  let data = {
    id: id
  }

  console.log(data);

  api.post("/venda/anexo", data).then((response) => {
    callback(response.data);
  })
  .catch((err) => {
    callback(err.response.data);
  })
}

export const pesquisaVendasUnidades = (dataInicio, dataFin, agencia, sr, sev, coD_UL, regiao, callback) => {
  let data = {
    dataInicio: dataInicio,
    dataFin: dataFin,
    agencia: agencia,
    sr: sr,
    sev: sev,
    coD_UL: coD_UL,
    regiao: regiao
  }

  console.log(data);

  api.post("/venda/lista-vendas-unidades", data).then((response) => {
      callback(response.data);
  })
  .catch((err) => {
    callback(err.response.data);
  })
}


export const pesquisaVendasUnidadesAgrupadas = (dataInicio, dataFin, agrupamento, tipoConsulta, txtBusqueda, regiao, callback) => {
  let data = {
    dataInicio: dataInicio,
    dataFin: dataFin,
    agrupamento: agrupamento,
    tipoConsulta: tipoConsulta,
    txtBusqueda: txtBusqueda,
    regiao: regiao
  }

  console.log(data);

  api.post("/venda/lista-vendas-unidades-agrupamento", data).then((response) => {
      callback(response.data);
  })
  .catch((err) => {
    callback(err.response.data);
  })
}

export const listaCarga = (callback) => {
  api.get("/carga/lista-carga").then((response) => {
    callback(response.data);
  })
  .catch((err) => {
    console.log(err);

    var response = {
      codigo: -1,
      mensagem: err
    }

    callback(response);    
  }) 
}

export const listaRelatorio = (callback, dataPesquisa) => {
  let data = {
    dataPesquisa: dataPesquisa
  }

  api.post("/SIGPF/lista-sigpf-relatorio", data).then((response) => {
    callback(response.data);
  })
  .catch((err) => {
    console.log(err);

    var response = {
      codigo: -1,
      mensagem: err
    }

    callback(response);    
  }) 
}

export const listaNormativos = (callback) => {
  api.get("/Normativo/lista-normativos").then((response) => {
    callback(response.data);
  })
  .catch((err) => {
    console.log(err);

    var response = {
      codigo: -1,
      mensagem: err
    }

    callback(response);    
  }) 
}

export const cadastrarNormativo = (data, callback) => {
  api.post("/Normativo/normativo", data,{headers: { "Content-Type": "multipart/form-data"}}).then((response) => {
    callback(response.data);
  })
  .catch((err) => {
    console.log(err);

    var response = {
      codigo: -1,
      mensagem: err
    }

    callback(response);    
  }) 
}

export const downloadNormativoAPI = (idArquivo, callback) => {
  api.get("/Normativo/normativo/" + idArquivo,{responseType: 'blob' }).then((response) => {
    callback(response.data);
  })
  .catch((err) => {
    console.log(err);

    var response = {
      codigo: -1,
      mensagem: err
    }

    callback(response);    
  }) 
}

export const excluirNormativo = (idArquivos, callback) => {
  api.delete("/Normativo/normativo", {headers: { "Content-Type": "application/json"},data: idArquivos}).then((response) => {
    callback(response.data);
  })
  .catch((err) => {
    console.log(err);

    var response = {
      codigo: -1,
      mensagem: err
    }

    callback(response);    
  }) 
}

export const pesquisaVendasTaspjunc = (dataInicio, dataFin, canal_macro, canal, tipo_pagamento, produto, callback) => {
  let data = {
    DataAutenticacaoInicio: dataInicio,
    DataAutenticacaoFim: dataFin,
    canal_macro: canal_macro,
    canal: canal,
    tipo_pagamento: tipo_pagamento,
    produto: produto
  }

  api.post("/venda/relatorio-taspjunc", data).then((response) => {
      callback(response.data);
  })
  .catch((err) => {
    callback(err.response.data);
  })
}


export const telasAtivas = (callback) => {
  api.get("/tela/lista-telas-ativas").then((response) => {
    callback(response.data);
  })
  .catch((err) => {
    console.log(err);

    var response = {
      codigo: -1,
      mensagem: err
    }

    callback(response);    
  }) 
}

export const listaTela = (callback) => {
  api.get("/tela/lista-telas-tv").then((response) => {
    callback(response.data);
  })
  .catch((err) => {
    console.log(err);

    var response = {
      codigo: -1,
      mensagem: err
    }

    callback(response);    
  }) 
}

export const salvarTela = (tela, callback) => {
  let data = {
    id: tela.id,
    nome: tela.nome,
    url: tela.url,
    descricao: tela.descricao,
    tempo: tela.tempo,
    situacao: tela.situacao
  }
  
  console.log(data);

  api.post("/tela/salvar", data).then((response) => {
      callback(response.data);
  })
  .catch((err) => {
    callback(err.response.data);
  })
}

export const retornaTelaPeloId = (id, callback) => {
  let data = {
    id: id
  }

  console.log(data);

  api.post("/tela/tela", data).then((response) => {
      console.log("retornaTelaPeloId", response);

      callback(response.data);
  })
  .catch((err) => {
    callback(err);
  })
}

export const vendasTotalDia = (callback) => {
  api.get("/painel/total-vendas-dia").then((response) => {
    callback(response.data);
  })
  .catch((err) => {
    console.log(err);

    var response = {
      codigo: -1,
      mensagem: err
    }

    callback(response);    
  }) 
}

export const conversao = (callback) => {
  api.get("/painel/conversao").then((response) => {
    callback(response.data);
  })
  .catch((err) => {
    console.log(err);

    var response = {
      codigo: -1,
      mensagem: err
    }

    callback(response);    
  }) 
}

export const efetividade = (callback) => {
  api.get("/painel/efetividade-regiao").then((response) => {
    callback(response.data);
  })
  .catch((err) => {
    console.log(err);

    var response = {
      codigo: -1,
      mensagem: err
    }

    callback(response);    
  }) 
}

export const vendasDia = (callback) => {
  api.get("/painel/vendas-dia").then((response) => {
    callback(response.data);
  })
  .catch((err) => {
    console.log(err);

    var response = {
      codigo: -1,
      mensagem: err
    }

    callback(response);    
  }) 
}

export const listaRelatorioSIGPFRegiao = (callback, dataPesquisa) => {
  let data = {
    dataPesquisa: dataPesquisa
  }

  api.get("/SIGPF/lista-sigpf-regiao", data).then((response) => {
    callback(response.data);
  })
  .catch((err) => {
    console.log(err);

    var response = {
      codigo: -1,
      mensagem: err
    }

    callback(response);    
  }) 
}