import React from "react";
import Cabecalho from "../cabecalho";
import MenuPage from "../menu";

export default function LogadoLayout({ children, icon, titulo, opcao, valorOverflow }) {
    if (valorOverflow != "hidden") {
        valorOverflow = "auto"
    }
    return (
        <div class="wrapper" >
            <header>
                <Cabecalho
                    titulo={titulo}
                    icon={icon}
                    opcao={opcao}>
                </Cabecalho>
            </header>
            <nav>
                <MenuPage></MenuPage>
            </nav>
            <section style={{ overflow: valorOverflow,paddingLeft:"10px" }}>
                <div className="flex flex-row items-center justify-between">
                    <div className="font-bold ml-4 titulo" style={{ fontSize: "18px" }}>{titulo}</div>
                    {opcao &&
                        <div className="mr-2 self-end">
                            {opcao}
                        </div>
                    }
                    </div>
                    <div>
                        {children}
                    </div>
                
            </section>
        </div>
    )
}
