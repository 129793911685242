import React, {useEffect, useState} from "react";
import Cabecalho from "../../componentes/cabecalho";
import MenuPage from "../../componentes/menu";
import { listaPerfil, retornaPerfilPeloId, salvarPerfil, listaFuncao } from "../../api/DataApi";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { FiPlus } from "react-icons/fi";
import Dialog from "../../componentes/dialog";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import LogadoLayout from "../../componentes/logado";

export default function PerfilPagina() {
    const [usuarios, setUsuarios] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openModalPerfil, setOpenModalPerfil] = useState(false);
    const [perfis, setPerfis] = useState();
    const [funcoes, setFuncoes] = useState();
    const [selFuncoes, setSelFuncoes] = useState([]);

    const [perfil, setPerfil] = useState({
        id: "",
        descricao: "",
        listaFuncoes: []
    });

    useEffect(() => {
        Pesquisa();
    }, []);

    const Pesquisa = (loading = true) => {
        setLoading(loading);

        listaPerfil(callBackListaPerfil);
    }

    const onOpenModalPerfil = (id) => {
        listaFuncao(callBackListaFuncao);

        if (id) {
            retornaPerfilPeloId(id, callBackRetornaPerfil);
        }
        else {
            setSelFuncoes([]);
            
            setPerfil( {
                id: "",
                descricao: ""
            } );

            setOpenModalPerfil(true);
        }
    }

    const callBackListaFuncao = (response) => {
        console.log("FUNCAO", response);
        if (response) {
            setFuncoes(response.listaFuncao);
        }
    }

    const callBackRetornaPerfil = (response) => {
        if (response) {
            console.log("PERFIL", response);

            if (response.listaFuncoes) {
                let buffFuncao = [];

                response.listaFuncoes.forEach(funcao => {
                    buffFuncao.push(funcao.id);
                })

                console.log("BUFFFUNCAO", buffFuncao);

                setSelFuncoes(buffFuncao);
            }

            setPerfil({
                id: response.perfil.id,
                descricao: response.perfil.descricao,
                listaFuncoes: response.listaFuncoes
            });

            setOpenModalPerfil(true);            
        }
    }

    const callBackListaPerfil = (response) => {
        if (response) {
            setPerfis(response.listaPerfil);
        }

        setLoading(false);
    }

    const onSalvar = () => {
        salvarPerfil(perfil, selFuncoes, callBackSalvarPerfil);
    }

    const callBackSalvarPerfil = (response) => {
        if (response && response?.codigo === 0) {
            setOpenModalPerfil(false);

            Pesquisa();
        }
    }

    const onChangeValue = (e) => {
        setPerfil({...perfil, [e.target.name]: e.target.value});
    }

    const onChangePermissao = (e) => {
        if (e.target.checked) {
            if (!selFuncoes.find(f => f === e.target.id)) {
                let buffFuncoes = [...selFuncoes];

                buffFuncoes.push(e.target.id);

                setSelFuncoes(buffFuncoes);
            }
        }
        else {
            let buffFuncoes = selFuncoes.filter(f => f !== e.target.id);

            setSelFuncoes(buffFuncoes)
        }
    }

    useEffect(() => {
        if (selFuncoes)
            console.log("selFuncoes", selFuncoes);
    }, [selFuncoes])

    return (
        <>
            <LogadoLayout titulo="Cadastro de Perfis"
                          icon={<AiOutlineUsergroupAdd size={30}/>}
                          opcao={
                            <button className="flex flex-row p-4 items-center justify-between" onClick={() => onOpenModalPerfil(null)}>
                                <FiPlus size={15}/>
                                Novo Perfil
                            </button> }>

                    <div className="overflow-auto p-5">
                        {loading ?
                            <Skeleton count={60} height={"30px"}/>
                        :
                            <div className="flex flex-col">
                                <table cellPadding={2} cellSpacing={2} border={0}>
                                    <thead>
                                        <th align="left">Descrição</th>
                                    </thead>
                                    <tbody>
                                        {perfis && perfis.map((perfil,index) => {
                                            return (
                                                <tr key={index} className="cursor-pointer bg-white hover:bg-gray-100" onClick={() => onOpenModalPerfil(perfil.id)}>
                                                    <td align="left">{perfil.descricao}</td>
                                                </tr>
                                            )
                                        })

                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
            </LogadoLayout>
            
            <Dialog open={openModalPerfil}
                    title={(perfil.id === ""?"Novo Perfil":perfil.descricao)}
                    onClose={() => setOpenModalPerfil(false)}
                    primaryButtonText="Salvar"
                    onPrimaryButtonClick={onSalvar}
                    width="500px">
                <div className="flex flex-col w-full">
                    <label htmlFor="tbxDescricao">Descrição</label>
                    <input value={perfil.descricao} name="descricao" id="tbxDescricao" type="text" onChange={onChangeValue}></input>

                    <div className="mt-4">Permissões</div>

                    <div className="overflow-auto h-60">
                        {funcoes && selFuncoes && funcoes.map((funcao,index) => {
                            return (
                                <div key={index} className="flex flex-row mt-2 items-center">
                                    <input name="permissoes" id={funcao.id} className="mr-3" type="checkbox" checked={(selFuncoes.find(p => p === funcao.id)) ? "checked" : ""} onChange={(e) => onChangePermissao(e)}></input>
                                    <div>{funcao.nome}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Dialog>
        </>
    )
}