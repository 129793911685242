import React from "react";
import { useNavigate } from "react-router-dom";
import LogoCaixa from '../../../imagens/caixa_assistencia_2d_vertical_negativa.png';

export default function MenuPainel(
    {
        titulo,
        texto,
        font
    }) {
    const navigate = useNavigate();


    const goHome = () => {
        navigate("../../home");
    }

    return (

        <div className="bg-caixa flex flex-row w-full items-center shadow p-5 justify-between " style={{ height: "150px" }}>
            <div >
                <img src={LogoCaixa} style={{ width: "200px" }} onClick={goHome} className="cursor-pointer logo"></img>
            </div>
            <div >
                <h1 className="h1-painel-conversao">{titulo}</h1>
            </div>
            <div style={{ width: "200px" }}><h1 className={font}>{texto}</h1></div>
        </div>
    )
}